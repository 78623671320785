import { useCallback, useEffect, useState } from "react";
import { API } from "../../../../../../controller/api/api";
import Checkbox from "@mui/material/Checkbox";
import { PrimeiraMaiuscula } from "../../../../../../controller/util";
import { SlideValue } from "../SlideValue";
import { BuscaLocal, GravaLocal } from "../../../../../../controller/storage";
import {
  MascaraMonetaria,
  MascaraNumerica,
} from "../../../../../../controller/data";
import { addItem, removeItem } from "../controller";

export function CarregaProdutos({ Codigo, Servico, Produto }) {
  let Local = BuscaLocal("v1/pedido/produtos/")
    ? JSON.parse(BuscaLocal("v1/pedido/produtos/"))
    : [];

  function DadosLocal() {
    if (Produto) {
      return Produto;
    }

    if (Local[0]?.codigoPedido == Codigo) {
      return Local;
    }

    return [];
  }

  const [Dados, setDados] = useState(DadosLocal());
  const [codigosSelecionados, setCodigosSelecionados] = useState([]);
  const [carregado, setCarregado] = useState(Produto ? true : false);

  function SelecionaCodigo(Codigo) {}

  useEffect(() => {
    if (!carregado) {
      setCarregado(true); // Define como carregado

      API.get("/v1/pedido/produtos/0" + Codigo).then(function (dados) {
        if (Local != dados.data) {
          setDados(dados.data);
        }

        let Body = [];
        for (var i = 0; i < dados.data.length; i++) {
          if (dados.data[i].selecionado == "1") {
            Body.push({
              codigo: dados.data[i].codigo,
              quantidade: dados.data[i].quantidade - dados.data[i].paga,
              valor:
                Servico > 0
                  ? (dados.data[i].valorTotal * Servico) / 100 +
                    dados.data[i].valorTotal -
                    dados.data[i].pagaTot
                  : dados.data[i].valorTotal,
              parcela:
                Servico > 0
                  ? (dados.data[i].valorTotal * Servico) / 100 +
                    dados.data[i].valorTotal -
                    dados.data[i].pagaTot
                  : dados.data[i].valorTotal - dados.data[i].pagaTot,
              max: dados.data[i].quantidade - dados.data[i].paga,
            });
          }
        }
        setCodigosSelecionados(Body);

        setCarregado(true); // Define como carregado
        GravaLocal("v1/pedido/produtos/", JSON.stringify(dados.data));
      });
    }
  }, [Codigo, carregado]); // Adiciona 'carregado' como dependência

  useEffect(() => {
    GravaLocal("QUANTIDADE_SELECIONADA", JSON.stringify(codigosSelecionados));
  }, [codigosSelecionados]);

  const Card = ({
    Codigo,
    Produto,
    Obs,
    Quantidade,
    Total,
    QuatidadePaga,
    TotalPago,
    Imagem,
    Servico,
    Selecionada,
  }) => {
    const handleCheckboxChange = (Codigo, Quantidade) => {
      const itemIndex = codigosSelecionados.findIndex(
        (item) => item.codigo === Codigo
      );

      if (itemIndex !== -1) {
        // Desmarcar o checkbox
        const newCodigosSelecionados = [...codigosSelecionados];
        newCodigosSelecionados.splice(itemIndex, 1);
        setCodigosSelecionados(newCodigosSelecionados);

        API.post(`/v2/pedido/produtos/seleciona/${Codigo}/0`);
        removeItem(Codigo);
        // setTotalSelecionados(TotalSelecionados - Total);
      } else {
        addItem(
          Codigo,
          Servico > 0 ? (Total * Servico) / 100 + Total - TotalPago : Total,
          Quantidade - QuatidadePaga
        );
        // Marcar o checkbox
        API.post(`/v2/pedido/produtos/seleciona/${Codigo}/1`);
        setCodigosSelecionados([
          ...codigosSelecionados,
          {
            codigo: Codigo,
            quantidade: Quantidade - QuatidadePaga,
            valor:
              Servico > 0 ? (Total * Servico) / 100 + Total - TotalPago : Total,
            parcela:
              Servico > 0 ? (Total * Servico) / 100 + Total - TotalPago : Total,
            max: Quantidade - QuatidadePaga,
          },
        ]);
        // setTotalSelecionados(TotalSelecionados + Total);
      }
    };

    const label = { inputProps: { "aria-label": "Checkbox demo" } };
    const [carregado, setCarregado] = useState(false);

    const handleQuantidadeChange = useCallback((codigo, quantidade) => {
      const newCodigosSelecionados = codigosSelecionados.map((item) =>
        item.codigo === codigo
          ? {
              ...item,
              quantidade: quantidade,
              parcela: (item.valor / item.quantidade) * quantidade,
            }
          : item
      );
      setCodigosSelecionados(newCodigosSelecionados);
    });

    return (
      <div
        key={Codigo}
        style={{
          display: "flex",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          marginBottom: "10px",
          padding: "10px",
          backgroundColor: "#ffffff",
          transition: "box-shadow 0.3s ease-in-out",
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.boxShadow = "0 8px 12px rgba(0, 0, 0, 0.1)")
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)")
        }
      >
        <div
          style={{
            flexShrink: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Checkbox
            onChange={(e) => handleCheckboxChange(Codigo, Quantidade)}
            checked={codigosSelecionados.some((item) => item.codigo === Codigo)}
            {...label}
            disabled={Quantidade == QuatidadePaga}
          />
        </div>
        <img
          style={{
            width: "100px",
            height: "100px",
            marginRight: "15px",
            borderRadius: "10px",
          }}
          src={Imagem}
          alt={Produto}
        ></img>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
          }}
        >
          <div>
            <h4>
              <b>{PrimeiraMaiuscula(Produto)}</b>
            </h4>
            <h4>{PrimeiraMaiuscula(Obs)}</h4>
            <h5>
              <b>
                {MascaraNumerica(Quantidade)}un {MascaraMonetaria(Total)}{" "}
              </b>
            </h5>
            {QuatidadePaga > 0 ? (
              <>
                <h5>
                  <b>
                    <i>
                      <span style={{ color: "#269931" }}>
                        Pago {MascaraNumerica(QuatidadePaga)}fr{" "}
                        {MascaraMonetaria(TotalPago)}
                      </span>{" "}
                    </i>
                  </b>
                </h5>
              </>
            ) : null}

            {Quantidade != QuatidadePaga ? (
              <>
                <h5>
                  <b>
                    <i>
                      <span style={{ color: "#A8001C" }}>
                        Restante {MascaraNumerica(Quantidade - QuatidadePaga)}fr{" "}
                        {MascaraMonetaria(
                          (Total * Servico) / 100 + Total - TotalPago
                        )}
                      </span>
                    </i>
                  </b>
                </h5>
              </>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "revert",
              marginLeft: "auto",
            }}
          >
            {Quantidade - QuatidadePaga != 0 ? (
              <>
                <SlideValue
                  Servico={Servico}
                  Quantidade={Quantidade - QuatidadePaga}
                  Total={(Total * Servico) / 100 + Total - TotalPago}
                  Ativo={codigosSelecionados.some(
                    (item) => item.codigo === Codigo
                  )}
                  Codigo={Codigo}
                  QuantidadeSelecionada={
                    BuscaLocal("QTD" + Codigo)
                      ? parseFloat(BuscaLocal("QTD" + Codigo))
                      : codigosSelecionados.find(
                          (item) => item.codigo === Codigo
                        )?.quantidade || 0
                  }
                  onQuantidadeChange={handleQuantidadeChange}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {Dados.map((dados) => (
        <Card
          Codigo={dados.codigo}
          Produto={dados.nomeProduto}
          Obs={dados.obs}
          Quantidade={dados.quantidade}
          Total={dados.valorTotal}
          QuatidadePaga={dados.paga}
          TotalPago={dados.pagaTot}
          Servico={Servico}
          Imagem={
            dados.imgIfood
              ? dados.imgIfood
              : `https://fotos.goopedir.com/fotos/${btoa(dados.site)}`
          }
          Selecionada={dados.selecionado}
        ></Card>
      ))}
    </>
  );
}
