import md5 from "crypto-js/md5";
// import * as React from 'react';
import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import axios from "axios";

import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BASEURL } from "../../controller/api/api";
import { Alert } from "@mui/material";
import { BuscaLocal, GravaLocal } from "../../controller/storage";
import Select from "react-select";
import { NotificaWarning } from "../../controller/util";
import LoadingOverlay from "../../component/load";
import { useParams } from "react-router-dom";

export function LoginPage() {
  const { baseurl } = useParams();
  const defaultTheme = createTheme();
  const [Senha, setSenha] = useState("");
  const [Usuario, setUsuario] = useState("");
  const [senhaErrada, setSenhaErada] = useState(false);
  const [loading, setLoading] = useState(true);

  const [Option, setOption] = useState([]);

  const [selectedOption, setSelectedOption] = useState(null);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#f5f5f5",
      minHeight: "34px",
      height: "34px",
      borderColor: "black",
      transition: "border-color 0.1s ease 0s",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#A8001C"
        : state.isFocused
        ? "#e5e5e5"
        : null,
      color: state.isSelected ? "white" : null,
    }),
  };

  function Login() {
    // setTest('http://192.168.0.64:9000/v1/usuario/'+Usuario+'/'+Senha);
    //console.log("Login");
    const API = axios.create({
      baseURL: BASEURL(),
      // baseURL: 'http://192.168.0.64:9000/',
      headers: {
        "Content-Type": "application/json; text/html; charset=utf-8",
      },
    });

    API.get("/v1/usuario/" + Usuario + "/" + Senha)
      .then(function (dados) {
        //console.log(dados);
        if (dados.data.length > 0) {
          GravaLocal("user", JSON.stringify(dados.data));
          const Agent = generateShortHash(
            navigator.appVersion +
              navigator.platform +
              navigator.vendor +
              currentDate +
              dados.data
          );
          GravaLocal("user_agent", Agent);
          window.location = "/principal";
        } else {
          setSenhaErada(true);
        }
        // Corrigir: Remover a chamada extra de setSenhaErada(false);
      })
      .catch(function (error) {
        console.error("Erro ao acessar o sistema: ", error); // Para ajudar na depuração
        NotificaWarning("Não foi possível acessar o sistema! " + BASEURL());
      });
  }

  useEffect(() => {
    let UrlCliente = baseurl;
    let urls = JSON.parse(BuscaLocal("HOST"));

    if (process.env.REACT_APP_HOST) {
      urls = JSON.parse(process.env.REACT_APP_HOST);
    }

    console.log(urls);

    if (UrlCliente) {
      for (let i = 0; i < urls.length; i++) {
        if (urls[i].url == UrlCliente) {
          console.log(urls[i]);
          GravaLocal("base-url", JSON.stringify(urls[i]));
          GravaLocal("url-login", "/" + UrlCliente);
          setSelectedOption(urls[i]);
          setLoading(false);
        }
      }
      return false;
    }

    setSelectedOption(JSON.parse(BuscaLocal("base-url")));
    // Supondo que a função BuscaLocal retorna o JSON armazenado no localStorage

    // Função para verificar o status de cada host
    const checkHostsStatus = async () => {
      try {
        // Mapeia os URLs para uma lista de Promises de requisições axios
        const requests = urls.map((item) =>
          axios
            .get(`${item.host}v1/versao/app`)
            .then(() => ({
              host: item.host,
              value: item.value,
              foto: item.foto,
              label: `${item.label} - Online`,
              online: true,
            }))
            .catch(() => ({
              host: item.host,
              value: item.value,
              foto: item.foto,
              label: `${item.label} - Offline`,
              online: false,
            }))
        );

        // Aguarda todas as requisições serem completadas
        const results = await Promise.all(requests);

        setOption(results);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao verificar o status dos hosts:", error);
      }
    };

    // Chama a função de verificação de status
    checkHostsStatus();
  }, []);

  // useEffect(() => {
  //   let Urls = JSON.parse(BuscaLocal("HOST"));
  // }, []);

  useEffect(() => {
    //console.log(Option);
  }, [Option]);

  useEffect(() => {
    // //console.log(Option[selectedOption?.value]);
    if (selectedOption != null) {
      // setTaxaEntrega(selectedOption.taxa);
      // setEntrega(selectedOption.taxa);
      // //console.log(selectedOption.taxa);
      //console.log(selectedOption.host);
      GravaLocal("base-url", JSON.stringify(selectedOption));
    }
  }, [selectedOption]);

  function generateMD5Hash(input) {
    const hash = md5(input).toString();
    return hash;
  }

  // Função para gerar um hash de 15 caracteres
  // Função para gerar um hash de 15 caracteres com hífen a cada 5 caracteres
  function generateShortHash(input) {
    const fullHash = generateMD5Hash(input);
    const shortHash = fullHash.substring(0, 15);
    const hashWithHyphens = shortHash.match(/.{1,5}/g).join("-"); // Inserir hífen a cada 5 caracteres
    return hashWithHyphens;
  }
  const currentDate = new Date();
  return (
    <>
      {" "}
      {loading && <LoadingOverlay />}{" "}
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />

          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ m: 1, bgcolor: "secondary.main", width: 100, height: 100 }}
              src={selectedOption?.foto ? selectedOption?.foto : null}
            >
              {selectedOption?.foto ? null : <LockOutlinedIcon />}
            </Avatar>
            <Typography component="h1" variant="h5">
              {selectedOption?.label ? selectedOption?.label : "Login GooPedir"}
            </Typography>

            <Box component="form" noValidate sx={{ mt: 1 }}>
              {Option.length != 0 ? (
                <>
                  <Select
                    value={selectedOption}
                    onChange={setSelectedOption}
                    options={Option}
                    isSearchable={true}
                    placeholder="Selecione ou pesquise..."
                    // styles={customStyles}
                  />
                </>
              ) : null}

              <>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="usuario"
                  label="Usuário"
                  name="usuario"
                  autoComplete="usuario"
                  autoFocus
                  value={Usuario}
                  onChange={(e) => setUsuario(e.target.value)}
                />
                <TextField
                  // disabled
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Senha"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={Senha}
                  onChange={(e) => setSenha(e.target.value)}
                />

                <Button
                  onClick={Login}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Acessar
                </Button>
                {senhaErrada ? (
                  <>
                    <Alert variant="outlined" severity="error">
                      Usuário ou Senha incorreto!
                    </Alert>
                  </>
                ) : null}
              </>

              {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
            </Box>
          </Box>
          {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
        </Container>
      </ThemeProvider>
    </>
  );
}
