import { CardFatura } from "../cardFatura";
import { CardProjecao } from "../cardProjecao";

export function DesktopPage() {
  var urlCompleta = window.location.href; // Obtém o URL completo
  var indexBarra = urlCompleta.indexOf("/", 8); // Procura a primeira barra após "https://"

  if (indexBarra !== -1) {
    var parteUrl = urlCompleta.substring(0, indexBarra + 1);
    //console.log(parteUrl);
  } else {
    //console.log("Não foi possível encontrar a barra após 'https://'");
  }
  return (
    <>
      <CardFatura />
      <CardProjecao />
    </>
  );
}
