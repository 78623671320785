import React from "react";
import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Adicionando o CloseIcon

const styles = {
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  imgContainer: {
    position: "relative",
    backgroundColor: "#000",
    flex: 1,
  },
  img: {
    position: "absolute",
    top: 20,
    bottom: 20,
    left: 20,
    right: 20,
    margin: "auto",
    maxWidth: "calc(100% - 40px)",
    maxHeight: "calc(100% - 40px)",
  },
};

const Transition = (props) => <Slide direction="up" {...props} />;

const ImgDialog = ({ img, onClose }) => {
  return (
    <Dialog
      fullScreen
      open={!!img}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar style={styles.appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" style={styles.flex}>
            Cropped image
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={styles.imgContainer}>
        <img src={img} alt="Cropped" style={styles.img} />
      </div>
    </Dialog>
  );
};

export default ImgDialog;
