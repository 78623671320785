export function GravaLocal(Categoria, Valor) {
  localStorage.setItem(Categoria, Valor);

  return Valor;
}

export function ApagaLocal(Categoria) {
  localStorage.removeItem(Categoria);
}

export function BuscaLocal(Categoria) {
  if (localStorage.getItem(Categoria) == "undefined") {
    return null;
  }
  return localStorage.getItem(Categoria);
}

export function GravaLocalJson(Categoria, Valor) {
  localStorage.setItem(Categoria, JSON.stringify(Valor));

  return Valor;
}

export function BuscaLocalJson(Categoria) {
  return JSON.parse(localStorage.getItem(Categoria));
}
