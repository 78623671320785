import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import { MascaraMonetaria } from "../../../../../../controller/data";
import { API } from "../../../../../../controller/api/api";
import { BuscaLocal, GravaLocal } from "../../../../../../controller/storage";
import { getQuantidade, setValues } from "../controller";

const Input = styled(MuiInput)`
  width: 60px;
`;

export function SlideValue({
  Quantidade,
  Total,
  Ativo,
  Codigo,
  onQuantidadeChange,
  QuantidadeSelecionada,
  Servico,
}) {
  const [value, setValue] = React.useState(QuantidadeSelecionada);
  const [pessoas, setPessoas] = React.useState(QuantidadeLocal());
  const inputRef = React.useRef(null);

  function QuantidadeLocal() {
    return getQuantidade(Codigo);
  }

  React.useEffect(() => {
    if (document.activeElement !== inputRef.current) {
      // setValue(QuantidadeSelecionada);
    }
    let qtd = QuantidadeLocal();

    document.querySelector(`input[name=por${Codigo}]`).value = qtd;

    AlteraCaptionValorSemAlterarStatus(Codigo, qtd);
  }, [QuantidadeSelecionada]);

  function AlteraCaptionValorSemAlterarStatus(code, quantidade) {
    let novoValor = parseFloat(Total) / parseFloat(quantidade);
    const elemento = document.querySelector(`p[name="b${code}"]`);
    if (elemento) {
      elemento.textContent = MascaraMonetaria(novoValor);
    }
    setValues(code, Total, quantidade);
  }

  const handleInputChangePessoa = (event) => {
    let valor = event.target.value;
    const newValue =
      event.target.value === "" ? 0 : parseFloat(event.target.value);

    if (newValue > 0) {
      GravaLocal("QTD" + Codigo, newValue);

      AlteraCaptionValorSemAlterarStatus(Codigo, newValue);
      // setPessoas(newValue);
      // setValue(Quantidade / newValue);
      // onQuantidadeChange(Codigo, Quantidade / newValue);
    }
  };

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Grid item>
            <h3>Por</h3>
            <Input
              disabled={!Ativo}
              size="small"
              onBlur={handleInputChangePessoa}
              name={`por${Codigo}`}
              style={{ textAlign: "center", marginBottom: "5px" }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Typography id="input-slider" gutterBottom>
        <p name={`b${Codigo}`}>
          {MascaraMonetaria((Total / Quantidade) * value)}
        </p>
      </Typography>
    </Box>
  );
}
