import { useEffect, useState } from "react";
import { InputPedido } from "../../../pedido/componente/input";
import { API } from "../../../../controller/api/api";
import {
  Notifica,
  NotificaWarning,
  PrimeiraMaiuscula,
} from "../../../../controller/util";
import { StatusComponent } from "../tabela/item/status";

import { Selected } from "../../../../component/selected";
import { BiCloset } from "react-icons/bi";
import axios from "axios";
import { Toggle } from "../../../../component/toggle";
import { Box, Button, TextField, Typography } from "@mui/material";
import { GetCorSite, GetCorSiteFonte } from "../../../../controller/modulos";
import LoadingOverlay from "../../../../component/load";

export function NovaCategoria({ codigo, type, close }) {
  // type 1 = Normal
  // type 2 = Pizza
  // codigo 0 = New

  const [Sabores, setSabores] = useState([]);
  const [NomeCategoria, setNomeCategoria] = useState("");
  const [DescricaoCategoria, setDescricaoCategoria] = useState("");
  const [Impressora, setImpressora] = useState("");
  const [ImpressoraSelecionada, setImpressoraSelecionada] = useState(0);
  const [Tipo, setTipo] = useState(type);
  const [CategoriaLocal, setCategoriaLocal] = useState(0);
  const [loading, setLoading] = useState(true);

  // Novos Campos
  const [BordaTopoDireito, setBordaTopoDireito] = useState(10);
  const [BordaTopoEsquerdo, setBordaTopoEsquerdo] = useState(10);
  const [BordaInferiorDireito, setBordaInferiorDireito] = useState(10);
  const [BordarInferiorEsquerdo, setBordarInferiorEsquerdo] = useState(10);
  const [Espacamento, setEspacamento] = useState(1);
  const [FontSizeNome, setFontSizeNome] = useState(16);
  const [FontSizeDescricao, setFontSizeDescricao] = useState(13);
  const [CorFundo, setCorFundo] = useState("#000000");
  const [CorFonteNome, setCorFonteNome] = useState("#ffffff");
  const [CorFonteDescricao, setCorFonteDescricao] = useState("#ffffff");

  const divStyle = {
    position: "fixed",
    top: "0",
    right: "0",
    width: "45%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255,1)",
    zIndex: "999",
    padding: "20px",
  };

  const divStyleBackground = {
    position: "fixed",
    top: "0",
    right: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "99",
  };

  function NovoSabor() {
    let MySabor = [...Sabores];
    MySabor.push({ id: 0, name: "", description: "", qtd: 1, status: 1 });
    setSabores(MySabor);
  }

  function seleciona(index, qtd) {
    let MySabor = [...Sabores];
    MySabor[index].qtd = qtd;
    setSabores(MySabor);
  }

  function Status(index, status) {
    let MySabor = [...Sabores];
    MySabor[index].status = status;
    setSabores(MySabor);
  }

  useEffect(() => {
    if (codigo > 0) {
      API.get(`v1/consulta/generica/tipo_produto/*/codigo = ${codigo}/*`).then(
        (response) => {
          setNomeCategoria(response.data[0].descricao);
          //console.log(response.data[0]);
          setImpressoraSelecionada(response.data[0].impressora);
          setDescricaoCategoria(response.data[0].descricaoCat);
          setBordaTopoDireito(response.data[0].bordaTopoDireito);
          setBordaTopoEsquerdo(response.data[0].bordaTopoEsquerdo);
          setBordaInferiorDireito(response.data[0].bordaInferiorDireito);
          setBordarInferiorEsquerdo(response.data[0].bordaInferiorEsquerdo);
          setEspacamento(response.data[0].espacamento);
          setFontSizeNome(response.data[0].fonteNome);
          setFontSizeDescricao(response.data[0].fonteDescricao);
          setCorFundo(
            response.data[0].corFundo ? response.data[0].corFundo : GetCorSite()
          );
          setCorFonteNome(
            response.data[0].corNome
              ? response.data[0].corNome
              : GetCorSiteFonte()
          );
          setCorFonteDescricao(
            response.data[0].corDescricao
              ? response.data[0].corDescricao
              : GetCorSiteFonte()
          );

          setTipo(1);

          API.get(`/v2/product/of/category/${codigo}`).then((response) => {
            let MySabor = [...Sabores];
            response.data.forEach((product) => {
              MySabor.push({
                id: product.id,
                name: PrimeiraMaiuscula(product.name),
                description: PrimeiraMaiuscula(product.description),
                qtd: product.sabores,
                status: product.status,
              });
            });
            setSabores(MySabor);
          });
          setLoading(false);
        }
      );
    } else {
      setCorFundo(GetCorSite());
      setCorFonteNome(GetCorSiteFonte());
      setCorFonteDescricao(GetCorSiteFonte());
      setLoading(false);
    }

    API.get(`v1/consulta/generica/impressoras/*/*/*`).then((response) => {
      let impressora = [];

      response.data.forEach((imp) => {
        if (imp.descricao != undefined) {
          impressora.push({
            id: imp.codigo,
            name:
              PrimeiraMaiuscula(imp.descricao) +
              ` (${PrimeiraMaiuscula(imp.driver)})`,
          });
        }
      });

      if (ImpressoraSelecionada == 0) {
        setImpressoraSelecionada(impressora[0].id);
      }

      setImpressora(impressora);
    });
  }, []);

  function AtualizaNomeCategoria(descricao, valor) {
    setNomeCategoria(valor);
  }

  function EnviarCategoriaPizza() {
    if (NomeCategoria == "") {
      NotificaWarning("Categoria não pode ser vazia!");
      return;
    }

    if (Tipo == 2) {
      if (Sabores.length == 0) {
        NotificaWarning("Deve-se informar pelomenos 1 tamanho!");
        return;
      }
      Sabores.forEach((sabor) => {
        if (sabor.name == "") {
          NotificaWarning("Tamanho não pode ser vazio!");
          return;
        }

        if (sabor.qtd < 0) {
          NotificaWarning("Sabores deve-ser selecionado!");
          return;
        }
      });
    }

    let Body = {};
    Body.id = codigo;
    Body.type = Tipo;
    Body.name = PrimeiraMaiuscula(NomeCategoria);
    Body.descricao = DescricaoCategoria;
    Body.bordatopodireito = BordaTopoDireito;
    Body.bordatopoesquerdo = BordaTopoEsquerdo;
    Body.bordainferiordireito = BordaInferiorDireito;
    Body.bordainferioresquerdo = BordarInferiorEsquerdo;
    Body.espacamento = Espacamento;
    Body.fontenomesize = FontSizeNome;
    Body.fontedescricaosize = FontSizeDescricao;
    Body.corfundo = CorFundo;
    Body.corfontenome = CorFonteNome;
    Body.corfontedescricao = CorFonteDescricao;
    Body.printer = ImpressoraSelecionada;
    Body.product = Sabores;
    Body.local = CategoriaLocal ? 1 : 0;

    // console.log(Body);
    // return Body;

    API.post("/v2/category", Body).then(function (response) {
      if (codigo == 0) {
        Notifica("Categoria adicionada!");
      } else {
        Notifica("Categoria alterada!");
      }
      // window.location.reload();
      close();
    });
  }

  function setaNome(index, valor) {
    let MySabor = [...Sabores];
    MySabor[index].name = valor;
    setSabores(MySabor);
  }

  function setDescricao(index, valor) {
    let MySabor = [...Sabores];
    MySabor[index].description = valor;
    setSabores(MySabor);
  }

  function funcaoSelected(valor) {
    setImpressoraSelecionada(valor);
    //console.log(valor);
  }

  return (
    <>
      {loading && <LoadingOverlay />}{" "}
      <div style={divStyleBackground}>
        <div style={divStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {/* Ícone de fechar */}
            {/* <BiCloset onClick={close} style={{ cursor: 'pointer' }} /> */}
            <strong onClick={close} style={{ cursor: "pointer" }}>
              X
            </strong>
          </div>

          <div style={{ height: "7rem" }}>
            <h1 style={{ fontSize: "2rem" }}>
              <strong>
                {NomeCategoria != ""
                  ? PrimeiraMaiuscula(NomeCategoria)
                  : "Nome Categoria"}
              </strong>
            </h1>
            <h4 style={{ fontSize: "1.3rem" }}>Detalhes Categoria</h4>
          </div>
          <div style={{ marginBottom: "25px" }}>
            <h4>Nome</h4>
            <div style={{ flex: "0 0 100%", zIndex: 1 }}>
              <InputPedido
                Descricao=""
                Set={setNomeCategoria}
                Valor={PrimeiraMaiuscula(NomeCategoria)}
              />
            </div>
            <h4>Descrição</h4>
            <div style={{ flex: "0 0 100%", zIndex: 1 }}>
              <InputPedido
                Descricao=""
                Set={setDescricaoCategoria}
                Valor={PrimeiraMaiuscula(DescricaoCategoria)}
              />
            </div>
            {Impressora.length > 0 ? (
              <>
                <h4>Local de Produção</h4>
                <h6 style={{ color: "#ccc" }}>
                  Onde a categoria atual será impressa a comanda de produção
                </h6>
                <div style={{ flex: "0 0 100%", zIndex: 1 }}>
                  <Selected
                    data={Impressora}
                    descricao={"name"}
                    value={"id"}
                    selected={ImpressoraSelecionada}
                    funcaoSelected={funcaoSelected}
                  />
                </div>{" "}
              </>
            ) : null}

            {codigo == 0 ? (
              <>
                <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                  <Button
                    variant={Tipo == 1 ? "contained" : "outlined"}
                    onClick={() => setTipo(1)}
                    sx={{ width: "50%" }}
                  >
                    Normal
                  </Button>

                  <Button
                    variant={Tipo == 2 ? "contained" : "outlined"}
                    onClick={() => setTipo(2)}
                    sx={{ width: "50%" }}
                  >
                    Pizza
                  </Button>
                </div>
              </>
            ) : null}
          </div>
          {Tipo == 2 ? (
            <>
              <div>
                <h1 style={{ fontSize: "1.7rem" }}>
                  <strong>Tamanhos</strong>
                </h1>
                <h4 style={{ fontSize: "1rem" }}>
                  Indique aqui quais os tamanhos que suas pizzas são produzidas,
                  em quantos pedaços são cortadas e até quantos sabores seu
                  restaurante monta cada tamanho:
                </h4>
                <div
                  onClick={NovoSabor}
                  style={{ color: "rgb(168, 0, 28)", marginBottom: "25px" }}
                >
                  <strong>+ Novo Tamanho</strong>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%" }}>
                  <h2>Nome</h2>
                </div>
                <div style={{ width: "350px" }}>
                  <h2>Descrição</h2>
                </div>
                <div style={{ width: "220px", textAlign: "center" }}>
                  <h2>Sabores</h2>
                </div>
                <div style={{ width: "220px", textAlign: "center" }}>
                  <h2>Status</h2>
                </div>
              </div>

              {Sabores.map((item, index) => (
                <div style={{ display: "flex" }} key={index}>
                  <div style={{ width: "100%", padding: "5px" }}>
                    <InputPedido
                      Descricao=""
                      index={index}
                      Retorno={setaNome}
                      Valor={PrimeiraMaiuscula(item.name)}
                    />
                  </div>
                  <div style={{ width: "350px", padding: "5px" }}>
                    <InputPedido
                      Descricao=""
                      index={index}
                      Retorno={setDescricao}
                      Valor={PrimeiraMaiuscula(item.description)}
                    />
                  </div>
                  <div
                    style={{
                      width: "220px",
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fit, minmax(20px, 1fr))",
                      gridRowGap: "3px",
                      padding: "2px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor:
                          item.qtd != 1 ? "#f7f7f7" : "rgb(168, 0, 28)",
                        color: item.qtd != 1 ? "black" : "white",
                        borderRadius: "20px",
                        height: "20px",
                        width: "20px",
                        textAlign: "center",
                        fontSize: "15px",
                      }}
                      onClick={() => seleciona(index, 1)}
                    >
                      1
                    </div>
                    <div
                      style={{
                        backgroundColor:
                          item.qtd != 2 ? "#f7f7f7" : "rgb(168, 0, 28)",
                        color: item.qtd != 2 ? "black" : "white",
                        borderRadius: "20px",
                        height: "20px",
                        width: "20px",
                        textAlign: "center",
                        fontSize: "15px",
                      }}
                      onClick={() => seleciona(index, 2)}
                    >
                      2
                    </div>
                    <div
                      style={{
                        backgroundColor:
                          item.qtd != 3 ? "#f7f7f7" : "rgb(168, 0, 28)",
                        color: item.qtd != 3 ? "black" : "white",
                        borderRadius: "20px",
                        height: "20px",
                        width: "20px",
                        textAlign: "center",
                        fontSize: "15px",
                      }}
                      onClick={() => seleciona(index, 3)}
                    >
                      3
                    </div>
                    <div
                      style={{
                        backgroundColor:
                          item.qtd != 4 ? "#f7f7f7" : "rgb(168, 0, 28)",
                        color: item.qtd != 4 ? "black" : "white",
                        borderRadius: "20px",
                        height: "20px",
                        width: "20px",
                        textAlign: "center",
                        fontSize: "15px",
                      }}
                      onClick={() => seleciona(index, 4)}
                    >
                      4
                    </div>
                  </div>
                  <div style={{ width: "220px", padding: "2px" }}>
                    <StatusComponent
                      status={item.status}
                      Ativar={() => Status(index, 1)}
                      Pausar={() => Status(index, 0)}
                    />
                  </div>
                </div>
              ))}
            </>
          ) : null}
          <Box sx={{ padding: 2 }}>
            <Typography variant="h7">Configurações Categoria</Typography>
            <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
              <TextField
                label="Borda Topo Direito"
                type="number"
                value={BordaTopoDireito}
                onChange={(e) => setBordaTopoDireito(parseInt(e.target.value))}
              />
              <TextField
                label="Borda Topo Esquerdo"
                type="number"
                value={BordaTopoEsquerdo}
                onChange={(e) => setBordaTopoEsquerdo(parseInt(e.target.value))}
              />
              <TextField
                label="Borda Inferior Direito"
                type="number"
                value={BordaInferiorDireito}
                onChange={(e) =>
                  setBordaInferiorDireito(parseInt(e.target.value))
                }
              />
              <TextField
                label="Borda Inferior Esquerdo"
                type="number"
                value={BordarInferiorEsquerdo}
                onChange={(e) =>
                  setBordarInferiorEsquerdo(parseInt(e.target.value))
                }
              />
            </Box>

            <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
              <TextField
                label="Espaçamento"
                type="number"
                value={Espacamento}
                onChange={(e) => setEspacamento(parseInt(e.target.value))}
              />
              <TextField
                label="Tamanho Fonte Nome"
                type="number"
                value={FontSizeNome}
                onChange={(e) => setFontSizeNome(parseInt(e.target.value))}
              />
              <TextField
                label="Tamanho Fonte Descrição"
                type="number"
                value={FontSizeDescricao}
                onChange={(e) => setFontSizeDescricao(parseInt(e.target.value))}
              />
            </Box>

            <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
              <TextField
                label="Cor de Fundo"
                type="color"
                value={CorFundo}
                onChange={(e) => setCorFundo(e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{ width: "33.33%" }}
              />
              <TextField
                label="Cor Fonte Nome"
                type="color"
                value={CorFonteNome}
                onChange={(e) => setCorFonteNome(e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{ width: "33.33%" }}
              />
              <TextField
                label="Cor Fonte Descrição"
                type="color"
                value={CorFonteDescricao}
                onChange={(e) => setCorFonteDescricao(e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{ width: "33.33%" }}
              />
            </Box>
            <Typography variant="h7">Preview Categoria No Site</Typography>
            <Box
              sx={{
                padding: Espacamento,
                backgroundColor: CorFundo,
                borderTopRightRadius: BordaTopoDireito,
                borderTopLeftRadius: BordaTopoEsquerdo,
                borderBottomRightRadius: BordaInferiorDireito,
                borderBottomLeftRadius: BordarInferiorEsquerdo,
                color: CorFonteNome,
                marginTop: 3,
              }}
            >
              <Typography variant="h6" sx={{ fontSize: FontSizeNome }}>
                {NomeCategoria ? NomeCategoria : "Nome da Categoria"}
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: FontSizeDescricao, color: CorFonteDescricao }}
              >
                {DescricaoCategoria
                  ? DescricaoCategoria
                  : "Descrição da Categoria"}
              </Typography>
            </Box>
          </Box>

          <div style={{ flexDirection: "row" }}>
            <Toggle
              Titulo={`Utilizar categoria apenas localmente e nas mesas no site`}
              Change={() => setCategoriaLocal(!CategoriaLocal)}
              Checked={CategoriaLocal}
            />
            <Button
              variant="outlined"
              onClick={EnviarCategoriaPizza}
              sx={{ width: "100%" }}
            >
              Salvar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
