// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes slideIn{
    from { right:-100%}
    to { right:0}
}`, "",{"version":3,"sources":["webpack://./src/component/modalPai/style.css"],"names":[],"mappings":"AAAA;IACI,OAAO,WAAW;IAClB,KAAK,OAAO;AAChB","sourcesContent":["@keyframes slideIn{\n    from { right:-100%}\n    to { right:0}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
