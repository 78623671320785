// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
}

.App-crop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls-crop {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 100%;
  padding: 0 16px;
  height: 80px;
  display: flex;
  align-items: center;
}

.slider-crop {
  padding: 22px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/component/cortarImage/styles.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,WAAW;EACX,eAAe;EACf,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n}\n\n.App-crop {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n\n.crop-container {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 80px;\n}\n\n.controls-crop {\n  box-sizing: border-box;\n  position: absolute;\n  bottom: 0;\n  left: 0%;\n  width: 100%;\n  padding: 0 16px;\n  height: 80px;\n  display: flex;\n  align-items: center;\n}\n\n.slider-crop {\n  padding: 22px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
