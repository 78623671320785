// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-overlay {
  position: fixed;
  /* Posiciona o overlay fixamente na janela */
  top: 0;
  left: 0;
  width: 100%;
  /* Ocupa toda a largura */
  height: 100%;
  /* Ocupa toda a altura */
  background-color: rgba(0, 0, 0, 0.7);
  /* Fundo preto com 70% de opacidade para desfocar */
  z-index: 99999999999;
  /* Garante que o overlay esteja acima de outros elementos */
  display: flex;
  /* Usa flexbox para centralizar o ícone */
  align-items: center;
  /* Centraliza verticalmente */
  justify-content: center;
  /* Centraliza horizontalmente */
}

.loading-icon {
  color: white;
  /* Cor do ícone */
  font-size: 3em;
  /* Tamanho do ícone */
}

body {
  font-family: "Roboto", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/component/load/LoadingOverlay.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,4CAA4C;EAC5C,MAAM;EACN,OAAO;EACP,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,wBAAwB;EACxB,oCAAoC;EACpC,mDAAmD;EACnD,oBAAoB;EACpB,2DAA2D;EAC3D,aAAa;EACb,yCAAyC;EACzC,mBAAmB;EACnB,6BAA6B;EAC7B,uBAAuB;EACvB,+BAA+B;AACjC;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":[".loading-overlay {\n  position: fixed;\n  /* Posiciona o overlay fixamente na janela */\n  top: 0;\n  left: 0;\n  width: 100%;\n  /* Ocupa toda a largura */\n  height: 100%;\n  /* Ocupa toda a altura */\n  background-color: rgba(0, 0, 0, 0.7);\n  /* Fundo preto com 70% de opacidade para desfocar */\n  z-index: 99999999999;\n  /* Garante que o overlay esteja acima de outros elementos */\n  display: flex;\n  /* Usa flexbox para centralizar o ícone */\n  align-items: center;\n  /* Centraliza verticalmente */\n  justify-content: center;\n  /* Centraliza horizontalmente */\n}\n\n.loading-icon {\n  color: white;\n  /* Cor do ícone */\n  font-size: 3em;\n  /* Tamanho do ícone */\n}\n\nbody {\n  font-family: \"Roboto\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
