import { API } from "../api/api";
import { BuscaLocal } from "../storage";
import moment from "moment";

export function getUsuario() {
  return JSON.parse(BuscaLocal("user"));
}

export function getCodigoUsuario() {
  let data = getUsuario();

  if (data !== null) {
    return data[0]["codigo"];
  } else {
    return 0;
  }
}

export function getCodigoMotoboy() {
  let data = JSON.parse(BuscaLocal("motoboy"));

  if (data !== null) {
    return data.codigo;
  } else {
    return 0;
  }
}

export async function getCaixa() {
  try {
    const response = await API.get(`/v1/caixa/aberto/${getCodigoUsuario()}`);
    const dados = response.data;

    return dados[0];
  } catch (error) {
    //////console.log(error);
    throw error;
  }
}

export async function getPedidosPeriodoCaixa(id) {
  try {
    const response = await API.get(`/v1/caixa/pedidos/dados/${id}`);
    const dados = response.data;

    return dados;
  } catch (error) {
    //////console.log(error);
    throw error;
  }
}

export function getTotalizadores(dados) {
  let totalValorPedidoPago = 0;
  let totalValorDescontoPago = 0;
  let totalValorTaxaEntregaPago = 0;
  let totalValorTotalPedidoPago = 0;
  let qtdPedidoPago = 0;

  let totalValorPedidoEmAberto = 0;
  let totalValorDescontoEmAberto = 0;
  let totalValorTaxaEntregaEmAberto = 0;
  let totalValorTotalPedidoEmAberto = 0;
  let qtdpedidoEmAberto = 0;

  dados.forEach((objeto) => {
    if (objeto.idCaixa > 0) {
      qtdPedidoPago++;
      totalValorPedidoPago += objeto.valorPedido;
      totalValorDescontoPago += objeto.valorDesconto;
      totalValorTaxaEntregaPago += objeto.valorTaxaEntrega;
      totalValorTotalPedidoPago += objeto.valorTotalPedido;
    } else {
      totalValorPedidoEmAberto += objeto.valorPedido;
      totalValorDescontoEmAberto += objeto.valorDesconto;
      totalValorTaxaEntregaEmAberto += objeto.valorTaxaEntrega;
      totalValorTotalPedidoEmAberto += objeto.valorTotalPedido;
      qtdpedidoEmAberto++;
    }
  });

  let data = [];
  data.push({
    type: "Pago",
    pedidos: qtdPedidoPago,
    valorPedido: totalValorPedidoPago,
    Desconto: totalValorDescontoPago,
    TaxaEntrega: totalValorTaxaEntregaPago,
    TotalPedido: totalValorTotalPedidoPago,
  });
  data.push({
    type: "Em Aberto",
    pedidos: qtdpedidoEmAberto,
    valorPedido: totalValorPedidoEmAberto,
    Desconto: totalValorDescontoEmAberto,
    TaxaEntrega: totalValorTaxaEntregaEmAberto,
    TotalPedido: totalValorTotalPedidoEmAberto,
  });
  return data;
}

function validarNumero(valor) {
  return !isNaN(parseFloat(valor)) && isFinite(valor);
}

export function formatarValor(valor) {
  try {
    let newvalor = valor;

    if (typeof valor === "string") {
      newvalor = newvalor.replace(",", ".");
    }

    newvalor = parseFloat(newvalor);

    if (Number.isInteger(newvalor)) {
      return newvalor.toString();
    } else {
      return newvalor.toFixed(3).replace(".", ",");
    }
  } catch (err) {
    return 0;
  }
}

export function TransformarValor(valor) {
  if (typeof valor != "string") {
    return valor;
  }
  let newvalor = valor.replace(",", ".");
  newvalor = parseFloat(newvalor);
  return newvalor;
}

export function MascaraMonetaria(valor) {
  let moedaFormatada = "BRL"; // Valor padrão
  let idioma = "pt-BR"; // Idioma padrão
  let moeda = "real";

  if (moeda) {
    if (moeda.toLowerCase() === "dolar") {
      moedaFormatada = "USD";
      idioma = "en-US";
    } else if (moeda.toLowerCase() === "euro") {
      moedaFormatada = "EUR";
      idioma = "de-DE";
    } else if (moeda.toLowerCase() === "real") {
      moedaFormatada = "BRL";
      idioma = "pt-BR";
    } else {
      console.warn(
        `Moeda ${moeda} não suportada, usando Real (BRL) como padrão.`
      );
    }
  }

  if (validarNumero(valor)) {
    const valorFormatado = formatarMoeda(valor, idioma, moedaFormatada);
    return valorFormatado;
  } else {
    return "";
  }
}

function formatarMoeda(valor, idioma, moeda) {
  let opcoes = {
    style: "currency",
    currency: moeda,
    minimumFractionDigits: 2,
    maximumFractionDigits: 3, // Permite até três casas decimais
  };

  let valorTemporario = valor.toLocaleString(idioma, opcoes);
  if (valorTemporario.slice(-1) === "5") {
    // Remove o último dígito decimal se for 5
    valorTemporario = valorTemporario.slice(0, -1);
  }
  return valorTemporario;
}

export function MascaraNumerica(valor) {
  if (validarNumero(valor)) {
    return valor
      .toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .replace(".", ",");
  } else {
    return "";
  }
}

export function MascaraInteiro(valor) {
  if (validarNumero(valor)) {
    return valor
      .toLocaleString("pt-BR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(".", ",");
  } else {
    return "";
  }
}

export async function FecharPedidosCaixa(id) {
  try {
    const response = await API.post(
      `/v1/caixa/fechamento/pedido/automatico/${id}`
    );
    const dados = response.data;
    //////console.log(dados);
    return dados;
  } catch (error) {
    //////console.log(error);
    throw error;
  }
}

export async function getTipoPagamentoCaixa(id) {
  try {
    const response = await API.get(`/v1/caixa/pedidos/pagamento/${id}`);
    const dados = response.data;

    return dados;
  } catch (error) {
    //////console.log(error);
    throw error;
  }
}

async function DadosParametroWhastapp() {
  try {
    const response = await API.get(`v1/consulta/generica/dados_whatsapp/*/*/*`);
    const dados = response.data;

    return dados[0];
  } catch (error) {
    //////console.log(error);
    throw error;
  }
}

export async function AbrirCaixa(valor) {
  const response = await API.post(
    `/v1/caixa/abertura/${getCodigoUsuario()}/${valor}`
  );
  if (response.status == 200) {
    return await getCaixa();
  }
}

export async function AbrirCaixaAutomatico() {
  try {
    const result = await getCaixa();
    //console.log(result);
    const parametro = await DadosParametroWhastapp();

    if (!result) {
      if ((await parametro.autoAbrirFecharCaixa) == 1) {
        let valorPadrao =
          parametro.valorPadraoCaixa > 0 ? parametro.valorPadraoCaixa : 0;

        const response = await API.post(
          `/v1/caixa/abertura/${getCodigoUsuario()}/${valorPadrao}`
        );
        if (response.status == 200) {
          return await getCaixa();
        }
      }
    } else {
      return result;
    }

    //autoAbrirFecharCaixa
  } catch (error) {
    //////console.log(error);
    throw error;
  }

  // getCaixa().then(result => {
  //   const parametro = await DadosParametroWhastapp();
  //   //////console.log(result);
  //   //////console.log(parametro);
  // })
  //Testar

  //

  // if (parametro.autoAbrirFecharCaixa == 1){
  //   //Abrir Caixa
  //   let valorPadrao =  parametro.valorPadraoCaixa > 0 ? parametro.valorPadraoCa : 0;
  //   //getCodigoUsuario()
  //   const response = await API.post(`/v1/caixa/abertura/${getCodigoUsuario()}/${valorPadrao}`);

  // } else {
  //   return false;
  // }
}

export async function FecharCaixa(data) {
  let parametro = DadosParametroWhastapp();

  getCaixa().then((result) => {
    //////console.log(result);
    let id = result.id;
    API.post(`/v1/caixa/fechamento/${id}`, data).then((result) => {
      //////console.log(result)
      if (parametro.impCaixaResumo == 1) {
        ImpressaoCaixaResumo(id);
      }

      if (parametro.impCaixaCompleto == 1) {
        ImpressaoCaixaCompleta(id);
      }

      if (parametro.impCaixaProduto == 1) {
        ImpressaoCaixaProduto(id);
      }

      if (parametro.impCaixaMotoboy == 1) {
        ImpressaoCaixaMotoboy(id);
      }
    });
  });
}

export function ImpressaoCaixaResumo(id) {
  API.post(`/v1/imprimir/3/${id}`);
}

export function ImpressaoCaixaCompleta(id) {
  API.post(`/v1/imprimir/4/${id}`);
}

export function ImpressaoCaixaProduto(id) {
  API.post(`/v1/imprimir/5/${id}`);
}

export function ImpressaoCaixaMotoboy(id) {
  API.post(`/v1/imprimir/6/${id}`);
}

export function calcularTempoDecorrido(dataHora) {
  const dataHoraFornecida = new Date(dataHora);
  const dataHoraAtual = new Date();

  const diferenca = Math.abs(dataHoraAtual - dataHoraFornecida);

  const segundosTotais = Math.floor(diferenca / 1000);
  const horas = Math.floor(segundosTotais / 3600);
  const minutos = Math.floor((segundosTotais % 3600) / 60);
  const segundos = segundosTotais % 60;

  return `${pad(horas)}:${pad(minutos)}:${pad(segundos)}`;
}

function pad(numero) {
  return numero.toString().padStart(2, "0");
}

export function formatarData(data) {
  try {
    const partes = data.split("-");
    const dataFormatada = `${partes[2]}/${partes[1]}/${partes[0]}`;
    return dataFormatada;
  } catch (error) {}
}

export function formatarHora(hora) {
  let horaFormatada = "";
  try {
    horaFormatada = hora.slice(0, 8);
  } catch (error) {}

  return horaFormatada;
}

export function formatarHoraMin(hora) {
  let horaFormatada = "";
  try {
    horaFormatada = hora.slice(0, 5);
  } catch (error) {}

  return horaFormatada;
}

export function removerTresHoras(dataString) {
  const data = new Date(dataString);

  // Subtrai 3 horas da data (cada hora tem 3.6e+6 milissegundos)
  data.setTime(data.getTime() - 3 * 3.6e6);

  return data.toISOString();
}

export function formatarDataHora(dataString) {
  const data = new Date(dataString);

  // Adiciona um zero à esquerda se o número for menor que 10
  const adicionarZero = (n) => (n < 10 ? "0" + n : n);

  const dia = adicionarZero(data.getUTCDate());
  const mes = adicionarZero(data.getUTCMonth() + 1); // Os meses começam de 0 (janeiro) até 11 (dezembro)
  const ano = data.getUTCFullYear();

  const hora = adicionarZero(data.getUTCHours());
  const minuto = adicionarZero(data.getUTCMinutes());

  return `${dia}/${mes}/${ano} ${hora}:${minuto}`;
}

export function RemoveMascara(valor) {
  if (typeof valor !== "string" && valor !== undefined) {
    valor = valor?.toString();
  }

  if (typeof valor !== "string") {
    return ""; // Retorna NaN se o valor não for uma string válida
  }
  let cleanedInput = valor.replace(/[^\d.,]/g, "");

  // Substitui o ponto por uma string vazia (remove pontos usados como separador de milhar)
  if (cleanedInput.includes(",")) {
    cleanedInput = cleanedInput.replace(/\./g, "");

    // Substitui a vírgula por um ponto (converte separador decimal)
    cleanedInput = cleanedInput.replace(/,/g, ".");
  }
  // // valor = valor.replace(".", "");
  // // const valorNumerico = valor.replace(/[^\d.,]/g, "").replace(",", ".");
  // const valorNumerico = valor
  //   .replace(/[^\d.,]/g, "")
  //   .replace(".", "")
  //   .replace(",", ".");
  // //console.log(valorNumerico);
  return parseFloat(cleanedInput);
}

export function transformarData(data) {
  const partes = data.split("/");
  const dataTransformada = `${partes[2]}-${partes[1]}-${partes[0]}`;
  return dataTransformada;
}

export function DataAtual() {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export function PrimeiroDiaDoMes() {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-01`;
  return formattedDate;
}

export function transformDate(dateString) {
  const [year, month, day] = dateString.split("-");
  const transformedDate = `${day}${month}${year}`;
  return transformedDate;
}

export function transformDatePadrao(dateString) {
  if (dateString == null) {
    return "";
  }
  const [year, month, day] = dateString.split("-");
  const transformedDate = `${day}/${month}/${year}`;
  return transformedDate;
}

export function obterHoraAtual() {
  function formatarNumero(numero) {
    return numero < 10 ? "0" + numero : numero;
  }
  const dataAtual = new Date();
  const hora = formatarNumero(dataAtual.getHours());
  const minutos = formatarNumero(dataAtual.getMinutes());
  const segundos = formatarNumero(dataAtual.getSeconds());
  return hora + ":" + minutos + ":" + segundos;
}

export function verificarData(dataString) {
  const dataAtual = new Date();

  // Convertendo a data para o formato "yyyy-mm-dd"
  const partesData = dataString.split("/");
  const dataConvertida = `${partesData[2]}-${partesData[1]}-${partesData[0]}`;

  const data = new Date(dataConvertida);

  const diaAtual = dataAtual.getDate();
  const mesAtual = dataAtual.getMonth();
  const anoAtual = dataAtual.getFullYear();

  const dia = data.getDate() + 1;
  const mes = data.getMonth();
  const ano = data.getFullYear();

  if (dia === diaAtual && mes === mesAtual && ano === anoAtual) {
    return "Hoje";
  } else if (dia === diaAtual && mes === mesAtual && ano === anoAtual) {
    return "Ontem";
  } else {
    return dataString;
  }
}

export function TextIdioma(text) {
  return text;
}

export function formatNumber(number) {
  return number.toLocaleString("de-DE", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

export function manterApenasNumeros(str) {
  // Use uma expressão regular para remover tudo que não é número
  return str.replace(/[^0-9]/g, "");
}
