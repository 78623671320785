// import React, { useState } from "react";
// import { Bar } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";

import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { ShowModal } from "../../../component/modalPai";
import { useEffect, useState } from "react";
import { Notifica, NotificaWarning } from "../../../controller/util";
import { API, GooPedirAPI } from "../../../controller/api/api";

export function ComponentPausa({ Close }) {
  const [Tempo, setTempo] = useState(0);
  const [Motivo, setMotivo] = useState(0);
  const [userId, setUserID] = useState(0);
  const [Data, setData] = useState(0);
  const [Hora, setHora] = useState(0);

  useEffect(() => {
    API.get("/v2/status").then((response) => {
      setUserID(response.data.user);
      //console.log(response.data);
    });

    let horario = 0;
    GooPedirAPI.get("ws/horario/atual.php").then(function (response) {
      console.log(response.data[0].horario);
      let horarioNovo = ajustarDataHora(response.data[0].horario);
      setData(horarioNovo.data);
      setHora(horarioNovo.hora);
    });
  }, []);

  useEffect(() => {
    console.log(Data);
    console.log(Hora);
  }, [Data, Hora]);

  function ajustarDataHora(dataHoraOriginal) {
    // Parse da string de data/hora original para um objeto Date
    const data = new Date(dataHoraOriginal);

    // Adiciona 24 horas (24 * 60 minutos * 60 segundos * 1000 milissegundos)
    data.setTime(data.getTime() + 24 * 60 * 60 * 1000);

    // Formata a data no formato desejado: "AAAA-MM-DD"
    const dataFormatada = [
      data.getFullYear(),
      ("0" + (data.getMonth() + 1)).slice(-2),
      ("0" + data.getDate()).slice(-2),
    ].join("-");

    // Extrai a hora no formato "HH:MM:SS"
    const horaFormatada = [
      ("0" + data.getHours()).slice(-2),
      ("0" + data.getMinutes()).slice(-2),
      ("0" + data.getSeconds()).slice(-2),
    ].join(":");

    // Retorna um objeto contendo a data e a hora separadas
    return {
      data: dataFormatada,
      hora: horaFormatada,
    };
  }

  async function FecharLoja() {
    if (Tempo == 0) {
      NotificaWarning("O tempo de fechamento deve ser selecionado!");
      return false;
    }
    if (Motivo == 0) {
      NotificaWarning("O motivo de fechamento deve ser selecionado!");
      return false;
    }
    let horario = 0;
    await GooPedirAPI.get("ws/horario/atual.php").then(function (response) {
      horario = response.data[0].horario;
    });

    let body = {
      user: userId,
      motivo: Description(),
      horario:
        Tempo == 8 ? `${Data} ${Hora}` : adicionarMinutos(horario, Minutos()),
    };

    GooPedirAPI.post("ws/horario/post.php", body).then((response) => {
      console.log(response.data);

      if ((response.data.status = "sucess")) {
        Notifica(response.data.message);
        Close();
      } else {
        NotificaWarning(response.data.message);
      }
    });
  }

  function Description() {
    if (Motivo == 1) {
      return "Muitos Pedidos";
    }
    if (Motivo == 2) {
      return "Manutenção/Problemas Técnicos";
    }
    if (Motivo == 3) {
      return "Horário de Funcionamento/Feriado";
    }
    if (Motivo == 4) {
      return "Falta de Equipe/Problemas Pessoais";
    }
    if (Motivo == 5) {
      return "Problema de Logística";
    }
  }

  function adicionarMinutos(dataHora, minutos) {
    // Converter a string para um objeto Date
    const data = new Date(dataHora.replace(" ", "T"));

    // Adicionar os minutos
    data.setMinutes(data.getMinutes() + minutos);

    // Retornar a nova data no formato original
    const ano = data.getFullYear();
    const mes = String(data.getMonth() + 1).padStart(2, "0");
    const dia = String(data.getDate()).padStart(2, "0");
    const horas = String(data.getHours()).padStart(2, "0");
    const minutosFormatados = String(data.getMinutes()).padStart(2, "0");
    const segundos = String(data.getSeconds()).padStart(2, "0");

    return `${ano}-${mes}-${dia} ${horas}:${minutosFormatados}:${segundos}`;
  }

  function Minutos() {
    if (Tempo == 1) {
      return 15;
    }
    if (Tempo == 2) {
      return 30;
    }
    if (Tempo == 3) {
      return 60;
    }
    if (Tempo == 4) {
      return 180;
    }
    if (Tempo == 5) {
      return 360;
    }
    if (Tempo == 6) {
      return 720;
    }
    if (Tempo == 7) {
      return 1440;
    }
  }

  return (
    <>
      <ShowModal closeButton={Close}>
        <h1 style={{ fontSize: "1.2rem", marginBottom: "1.5rem" }}>
          <b>Informe o Motivo Da Pausa Programada</b>
        </h1>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <FormControl>
            <FormLabel id="tempo">Fechar por quanto tempo?</FormLabel>
            <RadioGroup
              aria-labelledby="tempo"
              value={Tempo}
              onChange={(e) => setTempo(e.target.value)}
              name="radio-buttons-group-tempo"
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                >
                  <FormControlLabel value="1" control={<Radio />} label="15m" />
                  <FormControlLabel value="2" control={<Radio />} label="30m" />
                  <FormControlLabel value="3" control={<Radio />} label="1h" />
                  <FormControlLabel value="4" control={<Radio />} label="3h" />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "absolute",
                    right: 0, // Alinha a div à direita da div pai
                    top: 0,
                  }}
                >
                  <FormControlLabel value="5" control={<Radio />} label="6h" />
                  <FormControlLabel value="6" control={<Radio />} label="12h" />
                  <FormControlLabel value="7" control={<Radio />} label="24h" />
                  <FormControlLabel
                    value="8"
                    control={<Radio />}
                    label="Programada"
                  />
                </div>
              </div>
            </RadioGroup>
          </FormControl>

          {Tempo == 8 ? (
            <>
              <div style={{ display: "flex", gap: "10px" }}>
                <TextField
                  size="small"
                  type={"date"}
                  fullWidth
                  label={"Data"}
                  value={Data}
                  onChange={(e) => setData(e.target.value)}
                />

                <TextField
                  size="small"
                  type={"time"}
                  fullWidth
                  label={"Hora"}
                  value={Hora}
                  onChange={(e) => setHora(e.target.value)}
                />
              </div>
            </>
          ) : null}

          <FormControl>
            <FormLabel id="motivo">Qual motivo?</FormLabel>
            <RadioGroup
              aria-labelledby="motivo"
              value={Motivo}
              onChange={(e) => setMotivo(e.target.value)}
              name="radio-buttons-group-motivo"
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Muitos Pedidos"
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Manutenção/Problemas Técnicos"
              />
              <FormControlLabel
                value="3"
                control={<Radio />}
                label="Horário de Funcionamento/Feriado"
              />
              <FormControlLabel
                value="4"
                control={<Radio />}
                label="Falta de Equipe/Problemas Pessoais"
              />
              <FormControlLabel
                value="5"
                control={<Radio />}
                label="Problema de Logística"
              />
            </RadioGroup>
          </FormControl>
          <Button variant="outlined" onClick={FecharLoja}>
            Fechar Site
          </Button>
        </div>
      </ShowModal>
    </>
  );
}
