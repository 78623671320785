import { BuscaLocal } from "../storage";

export function PermissaoModulo(name) {
  const modulo = JSON.parse(BuscaLocal("modulos"));

  if (modulo == null) {
    return false;
  }

  if (typeof modulo[name] === "boolean") {
    // Se for booleano, retorna diretamente
    return modulo[name];
  } else if (typeof modulo[name] === "object" && modulo[name] !== null) {
    // Se for um objeto, retorna o status
    return modulo[name].status;
  }

  // Caso `modulo[name]` seja indefinido ou outro tipo, retorna `false`
  return false;
}

export function NomeModulo(name) {
  const modulo = JSON.parse(BuscaLocal("modulos"));
  if (modulo == null) {
    return "";
  }

  if (typeof modulo[name] === "boolean") {
    // Se for booleano, retorna diretamente
    return modulo[name];
  } else if (typeof modulo[name] === "object" && modulo[name] !== null) {
    // Se for um objeto, retorna o status
    return modulo[name].name;
  }

  // Caso `modulo[name]` seja indefinido ou outro tipo, retorna `false`
  return "";
}

export function GetDadosModulo(name) {
  const modulo = JSON.parse(BuscaLocal("modulos"));
  if (modulo == null) {
    return "";
  }

  return modulo[name];

  // Caso `modulo[name]` seja indefinido ou outro tipo, retorna `false`
  return "";
}

export function GetCorSite() {
  const site = JSON.parse(BuscaLocal("site"));

  return site.background;
}

export function GetCorSiteFonte() {
  const site = JSON.parse(BuscaLocal("site"));

  return site.titulo;
}

export function GetDadosSite() {
  return JSON.parse(BuscaLocal("site"));
}
