import { useEffect, useState } from "react";
import { API } from "../../../../controller/api/api";
import { ParametroConfiguracao } from "../../../../controller/parametros";
import { MascaraMonetaria, MascaraNumerica } from "../../../../controller/data";
import { Notifica } from "../../../../controller/util";
import { BuscaLocal, GravaLocal } from "../../../../controller/storage";

let quantidadeDias = 0;

export function CardProjecao() {
  const [Segunda, setSegunda] = useState(
    JSON.parse(BuscaLocal("Seg")) ? JSON.parse(BuscaLocal("Seg")) : {}
  );
  const [Terca, setTerca] = useState(
    JSON.parse(BuscaLocal("Ter")) ? JSON.parse(BuscaLocal("Ter")) : {}
  );
  const [Quarta, setQuarta] = useState(
    JSON.parse(BuscaLocal("Qua")) ? JSON.parse(BuscaLocal("Qua")) : {}
  );
  const [Quinta, setQuinta] = useState(
    JSON.parse(BuscaLocal("Qui")) ? JSON.parse(BuscaLocal("Qui")) : {}
  );
  const [Sexta, setSexta] = useState(
    JSON.parse(BuscaLocal("Sex")) ? JSON.parse(BuscaLocal("Sex")) : {}
  );
  const [Sabado, setSabado] = useState(
    JSON.parse(BuscaLocal("Sab")) ? JSON.parse(BuscaLocal("Sab")) : {}
  );
  const [Domingo, setDomingo] = useState(
    JSON.parse(BuscaLocal("Dom")) ? JSON.parse(BuscaLocal("Dom")) : {}
  );

  const [isSegunda, setisSegunda] = useState(false);
  const [isTerca, setisTerca] = useState(false);
  const [isQuarta, setisQuarta] = useState(false);
  const [isQuinta, setisQuinta] = useState(false);
  const [isSexta, setisSexta] = useState(false);
  const [isSabado, setisSabado] = useState(false);
  const [isDomingo, setisDomingo] = useState(false);
  const [ProjecaoMarketing, setProjecaoMarketing] = useState({});
  const [QuantidadeDias, setQuantidadeDias] = useState(0);

  function AdicionaDia(Data, Index) {
    return new Promise((resolve, reject) => {
      API.get("/v1/media/pedido/" + Data, {
        datas: Data,
      })
        .then(function (response) {
          if (Index == 1) {
            setSegunda(response.data[0]);
            GravaLocal("Seg", JSON.stringify(response.data[0]));
          }
          if (Index == 2) {
            setTerca(response.data[0]);
            GravaLocal("Ter", JSON.stringify(response.data[0]));
          }
          if (Index == 3) {
            setQuarta(response.data[0]);
            GravaLocal("Qua", JSON.stringify(response.data[0]));
          }
          if (Index == 4) {
            setQuinta(response.data[0]);
            GravaLocal("Qui", JSON.stringify(response.data[0]));
          }
          if (Index == 5) {
            setSexta(response.data[0]);
            GravaLocal("Sex", JSON.stringify(response.data[0]));
          }
          if (Index == 6) {
            setSabado(response.data[0]);
            GravaLocal("Sab", JSON.stringify(response.data[0]));
          }
          if (Index == 0) {
            setDomingo(response.data[0]);
            GravaLocal("Dom", JSON.stringify(response.data[0]));
          }
          resolve(); // Resolvendo a Promise quando os dados são recebidos e processados
        })
        .catch((error) => {
          reject(error); // Rejeitando a Promise em caso de erro
        });
    });
  }

  async function DiaSemana() {
    let hoje = new Date().getDay();
    let dataComeco = new Date();
    dataComeco.setDate(dataComeco.getDate() - hoje);
    let dataFim = new Date(dataComeco);
    dataFim.setDate(dataFim.getDate() + 7);

    let datas = Array(7).fill("");

    while (dataComeco.toDateString() !== dataFim.toDateString()) {
      let dataInicial = new Date(dataComeco);
      dataInicial.setDate(dataInicial.getDate() - 30);
      while (dataInicial.toDateString() !== dataComeco.toDateString()) {
        if (dataInicial.getDay() === dataComeco.getDay()) {
          let dataFormatada = `${dataInicial.getFullYear()}-${String(
            dataInicial.getMonth() + 1
          ).padStart(2, "0")}-${String(dataInicial.getDate()).padStart(
            2,
            "0"
          )}`;
          if (datas[dataInicial.getDay()] === "") {
            datas[dataInicial.getDay()] = `'${dataFormatada}'`;
          } else {
            datas[dataInicial.getDay()] += `,'${dataFormatada}'`;
          }
        }
        dataInicial.setDate(dataInicial.getDate() + 1);
      }
      dataComeco.setDate(dataComeco.getDate() + 1);
    }

    try {
      for (let i = 0; i < datas.length; i++) {
        await AdicionaDia(datas[i], i); // Espera pela resolução de cada Promise
      }
    } catch (error) {}
  }

  useEffect(() => {
    let quantidadeDias = 0;
    const fetchData = async () => {
      if ((await ParametroConfiguracao("segunda")) == 1) {
        setisSegunda(true);
        quantidadeDias++;
        setQuantidadeDias(quantidadeDias);
      } else {
        quantidadeDias++;
        setQuantidadeDias(quantidadeDias);
      }

      if ((await ParametroConfiguracao("terca")) == 1) {
        setisTerca(true);
        quantidadeDias++;
        setQuantidadeDias(quantidadeDias);
      } else {
        quantidadeDias++;
        setQuantidadeDias(quantidadeDias);
      }

      if ((await ParametroConfiguracao("quarta")) == 1) {
        setisQuarta(true);
        quantidadeDias++;
        setQuantidadeDias(quantidadeDias);
      } else {
        quantidadeDias++;
        setQuantidadeDias(quantidadeDias);
      }

      if ((await ParametroConfiguracao("quinta")) == 1) {
        setisQuinta(true);
        quantidadeDias++;
        setQuantidadeDias(quantidadeDias);
      } else {
        quantidadeDias++;
        setQuantidadeDias(quantidadeDias);
      }

      if ((await ParametroConfiguracao("sexta")) == 1) {
        setisSexta(true);
        quantidadeDias++;
        setQuantidadeDias(quantidadeDias);
      } else {
        quantidadeDias++;
        setQuantidadeDias(quantidadeDias);
      }

      if ((await ParametroConfiguracao("sabado")) == 1) {
        setisSabado(true);
        quantidadeDias++;
        setQuantidadeDias(quantidadeDias);
      } else {
        quantidadeDias++;
        setQuantidadeDias(quantidadeDias);
      }

      if ((await ParametroConfiguracao("domingo")) == 1) {
        setisDomingo(true);
        quantidadeDias++;
        setQuantidadeDias(quantidadeDias);
      } else {
        quantidadeDias++;
        setQuantidadeDias(quantidadeDias);
      }
    };

    fetchData();
    DiaSemana();
  }, []);

  useEffect(() => {
    if (QuantidadeDias == 7) {
      Marketing();
    }
    //
  }, [Segunda, Terca, Quarta, Quinta, Sexta, Sabado, Domingo]);

  function Marketing() {
    let QuantidadePedidos = 0;
    let QuantidadeDiasAberto = 0;
    let ValorTotalPedidos = 0;
    let DiasSemanaAbaixoMedia = "";
    let DiasSemanaAcimaMedia = "";

    if (isSegunda) {
      QuantidadePedidos += Segunda?.media;
      QuantidadeDiasAberto++;
      ValorTotalPedidos += Segunda?.total;
    }
    if (isTerca) {
      QuantidadePedidos += Terca?.media;
      QuantidadeDiasAberto++;
      ValorTotalPedidos += Terca?.total;
    }
    if (isQuarta) {
      QuantidadePedidos += Quarta?.media;
      QuantidadeDiasAberto++;
      ValorTotalPedidos += Quarta?.total;
    }
    if (isQuinta) {
      QuantidadePedidos += Quinta?.media;
      QuantidadeDiasAberto++;
      ValorTotalPedidos += Quinta?.total;
    }
    if (isSexta) {
      QuantidadePedidos += Sexta?.media;
      QuantidadeDiasAberto++;
      ValorTotalPedidos += Sexta?.total;
    }
    if (isSabado) {
      QuantidadePedidos += Sabado?.media;
      QuantidadeDiasAberto++;
      ValorTotalPedidos += Sabado?.total;
    }
    if (isDomingo) {
      QuantidadePedidos += Domingo?.media;
      QuantidadeDiasAberto++;
      ValorTotalPedidos += Domingo?.total;
    }

    let QuantidadeMediaPedidos = Math.round(
      QuantidadePedidos / QuantidadeDiasAberto
    );
    let ValorMediaPedidos = ValorTotalPedidos / QuantidadeDiasAberto;
    let QuantidadeAbaixo = 0;
    let QuantidadeAcima = 0;

    let QuantidadePedidoAbaixoMedia = 0;

    if (isSegunda) {
      if (Segunda?.media < QuantidadeMediaPedidos) {
        QuantidadePedidoAbaixoMedia++;
        QuantidadeAbaixo += Segunda?.media;

        DiasSemanaAbaixoMedia = "Seg";
      } else {
        QuantidadeAcima += Segunda?.media;

        if (DiasSemanaAcimaMedia != "") {
          DiasSemanaAcimaMedia += ", Seg";
        } else {
          DiasSemanaAcimaMedia = "Seg";
        }
      }
    }
    if (isTerca) {
      if (Terca?.media < QuantidadeMediaPedidos) {
        QuantidadePedidoAbaixoMedia++;
        QuantidadeAbaixo += Terca?.media;
        if (DiasSemanaAbaixoMedia != "") {
          DiasSemanaAbaixoMedia += ", Ter";
        } else {
          DiasSemanaAbaixoMedia = "Ter";
        }
      } else {
        QuantidadeAcima += Terca?.media;
        if (DiasSemanaAcimaMedia != "") {
          DiasSemanaAcimaMedia += ", Ter";
        } else {
          DiasSemanaAcimaMedia = "Ter";
        }
      }
    }
    if (isQuarta) {
      if (Quarta?.media < QuantidadeMediaPedidos) {
        QuantidadePedidoAbaixoMedia++;
        QuantidadeAbaixo += Quarta?.media;
        if (DiasSemanaAbaixoMedia != "") {
          DiasSemanaAbaixoMedia += ", Qua";
        } else {
          DiasSemanaAbaixoMedia = "Qua";
        }
      } else {
        QuantidadeAcima += Quarta?.media;
        if (DiasSemanaAcimaMedia != "") {
          DiasSemanaAcimaMedia += ", Qua";
        } else {
          DiasSemanaAcimaMedia = "Qua";
        }
      }
    }
    if (isQuinta) {
      if (Quinta?.media < QuantidadeMediaPedidos) {
        QuantidadePedidoAbaixoMedia++;
        QuantidadeAbaixo += Quinta?.media;
        if (DiasSemanaAbaixoMedia != "") {
          DiasSemanaAbaixoMedia += ", Qui";
        }
      } else {
        QuantidadeAcima += Quinta?.media;
        if (DiasSemanaAcimaMedia != "") {
          DiasSemanaAcimaMedia += ", Qui";
        } else {
          DiasSemanaAcimaMedia = "Qui";
        }
      }
    }
    if (isSexta) {
      if (Sexta?.media < QuantidadeMediaPedidos) {
        QuantidadePedidoAbaixoMedia++;
        QuantidadeAbaixo += Sexta?.media;
        if (DiasSemanaAbaixoMedia != "") {
          DiasSemanaAbaixoMedia += ", Sex";
        }
      } else {
        QuantidadeAcima += Sexta?.media;
        if (DiasSemanaAcimaMedia != "") {
          DiasSemanaAcimaMedia += ", Sex";
        } else {
          DiasSemanaAcimaMedia = "Sex";
        }
      }
    }
    if (isSabado) {
      if (Sabado?.media < QuantidadeMediaPedidos) {
        QuantidadePedidoAbaixoMedia++;
        QuantidadeAbaixo += Sabado?.media;
        if (DiasSemanaAbaixoMedia != "") {
          DiasSemanaAbaixoMedia += ", Sab";
        }
      } else {
        QuantidadeAcima += Sabado?.media;
        if (DiasSemanaAcimaMedia != "") {
          DiasSemanaAcimaMedia += ", Sab";
        } else {
          DiasSemanaAcimaMedia = "Sab";
        }
      }
    }
    if (isDomingo) {
      if (Domingo?.media < QuantidadeMediaPedidos) {
        QuantidadePedidoAbaixoMedia++;
        QuantidadeAbaixo += Domingo?.media;
        if (DiasSemanaAbaixoMedia != "") {
          DiasSemanaAbaixoMedia += ", Dom";
        }
      } else {
        QuantidadeAcima += Domingo?.media;
        if (DiasSemanaAcimaMedia != "") {
          DiasSemanaAcimaMedia += ", Dom";
        } else {
          DiasSemanaAcimaMedia = "Dom";
        }
      }
    }

    let QuantidadePedidosEstimado = Math.round(
      QuantidadeMediaPedidos * QuantidadePedidoAbaixoMedia -
        QuantidadeAbaixo +
        ((QuantidadeMediaPedidos * QuantidadePedidoAbaixoMedia -
          QuantidadeAbaixo) *
          0.25) /
          QuantidadePedidoAbaixoMedia
    );
    let ValorDesconto =
      Math.floor(
        ((ValorMediaPedidos * 0.2 + ValorMediaPedidos) /
          QuantidadePedidosEstimado) *
          100
      ) / 100;
    let PedidoMin = Math.floor(ValorDesconto * 4 * 100) / 100;

    let Dados = {
      DiaSemanaAbaixo: DiasSemanaAbaixoMedia,
      DiaSemanaAcima: DiasSemanaAcimaMedia,
      QuantidadeDiasAbaixo: QuantidadePedidoAbaixoMedia,
      QuantidadePedidoAbaixo: QuantidadeAbaixo,
      QuedaPercentual:
        ((QuantidadeAcima - QuantidadeAbaixo) / QuantidadeAcima) * 100,
      QuantidadeCupom: QuantidadePedidosEstimado,
      ValorCupom: ValorDesconto,
      PedidoMin: PedidoMin,
      QuantidadeTotalCupom:
        QuantidadePedidosEstimado * QuantidadePedidoAbaixoMedia,
      TotalDesconto:
        QuantidadePedidosEstimado * QuantidadePedidoAbaixoMedia * ValorDesconto,
      ValorArrecadado:
        (ValorDesconto * 4 - ValorDesconto) *
        (QuantidadePedidosEstimado * QuantidadePedidoAbaixoMedia),
      ValorArrecadadoMes:
        (ValorDesconto * 4 - ValorDesconto) *
        (QuantidadePedidosEstimado * QuantidadePedidoAbaixoMedia) *
        4,
    };

    setProjecaoMarketing(Dados);
  }

  function AtualizaParametro(campo, valor) {
    let Body = { campo: campo, valor: valor };

    API.post(`/v2/parametro`, Body);
  }

  // const Card = ({ data, descricao }) => {
  //   return (
  //     <>
  //       <div
  //         style={{
  //           width: "100%",
  //           border:
  //             getDayOfWeek() == descricao
  //               ? "5px dashed black"
  //               : "1px dashed black",
  //           borderRadius: "10px",
  //           marginRight: "10px",
  //           padding: "10px",
  //         }}
  //       >
  //         <h1>
  //           <strong>{descricao}</strong>
  //         </h1>
  //         <div style={{ display: "flex", justifyContent: "space-between" }}>
  //           <h4>Pedidos (média)</h4>
  //           <span>
  //             <strong>{data?.media}</strong>
  //           </span>
  //         </div>
  //         <div style={{ display: "flex", justifyContent: "space-between" }}>
  //           <h4>Cancelamento</h4>
  //           <span>
  //             <strong>{data?.mediaCancelado}%</strong>
  //           </span>
  //         </div>
  //         <div style={{ display: "flex", justifyContent: "space-between" }}>
  //           <h4>Concluido</h4>
  //           <span>
  //             <strong>{data?.mediaConcluido}%</strong>
  //           </span>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  const Card = ({ data, descricao }) => {
    const isSelected = getDayOfWeek() === descricao;

    return (
      <>
        <div
          style={{
            width: "100%",
            borderRadius: "12px",
            marginRight: "10px",
            padding: "20px",
            backgroundColor: "#f9f9f9",
            boxShadow: isSelected
              ? "inset 0 0 0 3px #4A90E2, 0 4px 8px rgba(0, 0, 0, 0.1)" // Borda interna azul e sombra externa
              : "0 4px 8px rgba(0, 0, 0, 0.1)", // Apenas sombra externa quando não selecionado
            transition: "transform 0.3s ease", // Animação suave ao passar o mouse
            cursor: "pointer",
            transform: isSelected ? "scale(1.05)" : "scale(1)",
          }}
        >
          <h1
            style={{ fontSize: "1.2rem", color: "#333", marginBottom: "15px" }}
          >
            <strong>{descricao}</strong>
          </h1>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <h4 style={{ margin: 0, color: "#555", fontSize: "0.85rem" }}>
              Pedidos (média)
            </h4>
            <span style={{ fontSize: "0.95rem", color: "#333" }}>
              <strong>{data?.media}</strong>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <h4 style={{ margin: 0, color: "#555", fontSize: "0.85rem" }}>
              Cancelamento
            </h4>
            <span style={{ fontSize: "0.95rem", color: "#333" }}>
              <strong>{data?.mediaCancelado}%</strong>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4 style={{ margin: 0, color: "#555", fontSize: "0.85rem" }}>
              Concluído
            </h4>
            <span style={{ fontSize: "0.95rem", color: "#333" }}>
              <strong>{data?.mediaConcluido}%</strong>
            </span>
          </div>
        </div>
      </>
    );
  };

  function getDayOfWeek() {
    const date = new Date();
    const days = [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado",
    ];
    const dayName = days[date.getDay()];
    return dayName;
  }

  return (
    <>
      {QuantidadeDias == 7 ? (
        <>
          {ProjecaoMarketing?.QuantidadeDiasAbaixo > 0 ? (
            <>
              <h1>
                <strong>
                  Previsão de pedidos, com base nos últimos{" "}
                  <span style={{ color: "rgb(168, 0, 28)" }}>30</span> dias
                </strong>
              </h1>
              <div style={{ display: "flex", margin: "20px" }}>
                {isSegunda ? <Card descricao="Segunda" data={Segunda} /> : null}
                {isTerca ? <Card descricao="Terça" data={Terca} /> : null}
                {isQuarta ? <Card descricao="Quarta" data={Quarta} /> : null}
                {isQuinta ? <Card descricao="Quinta" data={Quinta} /> : null}
                {isSexta ? <Card descricao="Sexta" data={Sexta} /> : null}
                {isSabado ? <Card descricao="Sábado" data={Sabado} /> : null}
                {isDomingo ? <Card descricao="Domingo" data={Domingo} /> : null}
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
}
