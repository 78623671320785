import React, { useState } from "react";
import { MascaraMonetaria } from "../../../../controller/data";
import { MascaraCelular, PrimeiraMaiuscula } from "../../../../controller/util";

export function CardBusca({ nome, cpf, celular, devedor, pago, onClique }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const divStyle = {
    display: "flex",
    cursor: "pointer",
    background: isHovered ? "lightgray" : "transparent",
    transform: isHovered ? "scale(1.03)" : "scale(1)",
    fontWeight: isHovered ? "bold" : "normal",
  };

  const firstDivStyle = {
    width: "70%",
    borderBottom: isHovered ? "transparent" : "1px solid black",
    borderRight: isHovered ? "transparent" : "1px solid black",
    borderLeft: "1px solid transparent",
    paddingLeft: "5px",
    fontSize: "0.8rem",
  };

  const secondDivStyle = {
    width: "15%",
    paddingLeft: "5px",
    borderBottom: isHovered ? "transparent" : "1px solid black",
    textAlign: "center",
    display: "flex",
    alignItems: "flex-end",
    fontSize: "0.8rem",
  };

  const thirdDivStyle = {
    width: "15%",
    paddingLeft: "5px",
    borderBottom: isHovered ? "transparent" : "1px solid black",
    borderLeft: isHovered ? "transparent" : "1px solid black",
    fontSize: "0.8rem",
    textAlign: "center",
    display: "flex",
    alignItems: "flex-end",
  };

  return (
    <>
      <div style={divStyle}>
        <div
          style={firstDivStyle}
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
          onClick={onClique}
        >
          <div>
            <h3>{PrimeiraMaiuscula(nome)}</h3>
            <h4>{cpf}</h4>
            <h4>{MascaraCelular(celular)}</h4>
          </div>
        </div>
        <div style={secondDivStyle}>
          {devedor > 0 ? MascaraMonetaria(devedor) : MascaraMonetaria(0)}
        </div>
        <div style={thirdDivStyle}>{MascaraMonetaria(pago)}</div>
      </div>
    </>
  );
}
