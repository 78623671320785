import React, { useEffect, useState } from "react";
import "./styles.css";
import { API } from "../../controller/api/api";
import { HeaderComponent } from "../../component/header";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { AdicionaComponent } from "./component/adicional";
import { MdRemove, MdAdd } from "react-icons/md";
import {
  BuscaLocal,
  BuscaLocalJson,
  GravaLocal,
  GravaLocalJson,
} from "../../controller/storage";
import {
  Notifica,
  NotificaWarning,
  PrimeiraMaiuscula,
} from "../../controller/util";

import { Button } from "../../component/button";
import {
  MascaraMonetaria,
  formatarValor,
  getCodigoUsuario,
  getUsuario,
} from "../../controller/data";
import { PizzaComponent } from "./component/pizza";
import { ParametroConfiguracao } from "../../controller/parametros";
import LoadingOverlay from "../../component/load";
import { InputPedido } from "../pedido/componente/input";
import { ListProduct } from "./component/listproduct/listproduct";
import { IconButton, TextField } from "@mui/material";

var DadosSelecionado = {};
var DadosPizza = [];

// Componente para cada item da lista
const ListItem = ({ text, style, onClick }) => {
  return (
    <li onClick={() => onClick()}>
      <a style={style}>{text}</a>
    </li>
  );
};

var tot = 0;
var qtd = 1;
var adc = 0;
var totalizador = 0;
var DadosAtuais = BuscaLocalJson("ProdutosCategorias")
  ? BuscaLocalJson("ProdutosCategorias")
  : [];

export function AddProdutoPage() {
  const { pedido } = useParams();
  const { mesa } = useParams();
  const [categoria, SetCategoria] = useState(
    BuscaLocalJson("Categorias") ? BuscaLocalJson("Categorias") : []
  );

  const [produtosCategoria, SetProdutosCategoria] = useState(
    BuscaLocalJson("ProdutosCategorias")
      ? BuscaLocalJson("ProdutosCategorias")
      : []
  );
  const [categoriaSelecionada, SetCategoriaSelecionada] = useState(
    BuscaLocal("CategoriaSelecionada")
      ? parseInt(BuscaLocal("CategoriaSelecionada"))
      : 0
  );

  const [ProdutoSelecionado, SetProdutoSelecionado] = useState(0);
  const [DadosProdutoSelecionado, SetDadosProdutoSele] = useState([]);

  const [Input, SetInput] = useState("");

  const [loading, setloading] = useState(false);
  const [
    DadosAdicionasiProdutoSelecionado,
    setDadosAdicionasiProdutoSelecionado,
  ] = useState([]);
  const [DadosSabores, setDadosSabores] = useState([]);
  const [text, setText] = useState("");
  const [ValorTotal, setValorTotal] = useState(tot);
  const [QuantidadeAtual, setQuantidadeAtual] = useState(qtd);
  const [ValorAdicional, setValorAdicional] = useState(adc);
  const [Totalizador, setTotalizador] = useState(totalizador);

  const [ProductSelected, setProductSelected] = useState([]);

  const [AllProduct, setAllProduct] = useState(
    BuscaLocalJson("AllProdutos") ? BuscaLocalJson("AllProdutos") : []
  );

  async function EnvarMaisDeUmProduto() {
    setloading(true);

    for (let i = 0; i < ProductSelected.length; i++) {
      if (ProductSelected[i].stock) {
        const response = await API.get(
          `v2/estoque/produto/insulmo/1/${ProductSelected[i].id}`
        );

        let estoque = response.data[0]?.estoque ? response.data[0]?.estoque : 0;
        if (ProductSelected[i].qtd > estoque) {
          setloading(false);
          NotificaWarning(
            `Estoque atual é ${estoque}, e o total selecionado é de ${ProductSelected[i].qtd}, você pode selecionar no máximo ${estoque}!`
          );
          return false;
        }
      }
    }

    var body = [];
    for (var i = 0; i < ProductSelected.length; i++) {
      body.push({
        mesa: mesa || 0,
        produto: ProductSelected[i].id,
        qtd:
          ProductSelected[i].qtd != 0
            ? parseFloat(substituirVirgulaPorPonto(ProductSelected[i].qtd))
            : 1,
        adicionais: "",
        pizza: "",
        observacao: ProductSelected[i].obs,
        pedido: parseInt(pedido),
        valorProduto: parseFloat(ProductSelected[i].total),
      });
    }

    API.post(`/v2/grava/varios/produtos`, body).then(function (dados) {
      navigate(BuscaLocal("VOLTAR"));
      setloading(false);
      return false;
    });
  }

  function QuantidadeTotalSelecionada() {
    let soma = 0;
    for (let i = 0; i < ProductSelected.length; i++) {
      soma += ProductSelected[i].qtd;
    }
    return soma;
  }

  function TotalSelecionada() {
    let soma = 0;
    for (let i = 0; i < ProductSelected.length; i++) {
      soma += ProductSelected[i].total * ProductSelected[i].qtd;
    }
    return soma;
  }

  function SelectProduct(id, total, stock) {
    // setloading(true);
    const produtoExistenteIndex = ProductSelected.findIndex(
      (item) => item.id === id
    );

    if (produtoExistenteIndex === -1) {
      // Se o produto não existe, adiciona um novo objeto com o id e a quantidade
      setProductSelected((prevState) => [
        ...prevState,
        { id: id, qtd: 1, total: total, stock: stock, obs: "" },
      ]);
    } else {
      // Se o produto já existe, você pode remover o registro
      const updatedProductSelected = ProductSelected.filter(
        (_, index) => index !== produtoExistenteIndex
      );
      setProductSelected(updatedProductSelected);
    }
  }

  function ObsProductProduct(id, obs) {
    // setloading(true);
    const produtoExistenteIndex = ProductSelected.findIndex(
      (item) => item.id === id
    );

    if (produtoExistenteIndex === -1) {
    } else {
      // Se o produto já existe, você pode remover o registro
      const updatedProductSelected = ProductSelected.map((item, index) =>
        index === produtoExistenteIndex ? { ...item, obs: obs } : item
      );
      setProductSelected(updatedProductSelected);
    }
  }

  function ProductStatusSelected(id) {
    const produtoExistenteIndex = ProductSelected.findIndex(
      (item) => item.id === id
    );

    return produtoExistenteIndex > -1;
  }

  function ProductObsSelected(id) {
    const produtoExistenteIndex = ProductSelected.findIndex(
      (item) => item.id === id
    );

    return ProductSelected[produtoExistenteIndex].obs;
  }

  function ProductSelectedQuant(id) {
    const produtoExistenteIndex = ProductSelected.findIndex(
      (item) => item.id === id
    );

    return ProductSelected[produtoExistenteIndex].qtd
      ? ProductSelected[produtoExistenteIndex].qtd
      : 0;
  }

  function ProdutctSelectedAdd(id) {
    const produtoExistenteIndex = ProductSelected.findIndex(
      (item) => item.id === id
    );

    const updatedProductSelected = [...ProductSelected];
    updatedProductSelected[produtoExistenteIndex].qtd += 1;
    setProductSelected(updatedProductSelected);
  }

  function ProdutctSelectedRemove(id) {
    const produtoExistenteIndex = ProductSelected.findIndex(
      (item) => item.id === id
    );
    const updatedProductSelected = [...ProductSelected];
    if (updatedProductSelected[produtoExistenteIndex].qtd == 1) {
      return 0;
    }

    updatedProductSelected[produtoExistenteIndex].qtd -= 1;
    setProductSelected(updatedProductSelected);
  }

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const remainingCharacters = 200 - text.length;

  function SelecionouProduto() {
    setValorAdicional(0);
    setQuantidadeAtual(0);
    setValorTotal(0);
  }

  function DadosSelecionadoQueForam(data) {
    DadosSelecionado = data;
    //console.log(data);
  }

  function DadosSelecionadoQueForamPizza(data) {
    DadosPizza = data;
    SaboresSelecionado();
  }

  function substituirVirgulaPorPonto(texto) {
    if (typeof texto !== "string") {
      // throw new Error('O argumento deve ser uma string');
      return texto;
    }

    return texto.replace(/,/g, ".");
  }

  function subistituePontoPorVirgula(texto) {
    if (typeof texto !== "string") {
      throw new Error("O argumento deve ser uma string");
      return 0;
    }

    return texto.replace(/\./g, ",");
  }

  function Total() {
    totalizador =
      (tot + adc) * parseFloat(substituirVirgulaPorPonto(QuantidadeAtual));
    // NotificaWarning("TEST" + totalizador);
    setTotalizador(totalizador);
  }

  function QtdAdd() {
    qtd = parseFloat(QuantidadeAtual) + 1;

    setQuantidadeAtual(qtd);
    Total();
  }

  function QtdRemove() {
    if (parseFloat(QuantidadeAtual) > 1) {
      qtd = parseFloat(QuantidadeAtual) - 1;

      setQuantidadeAtual(qtd - 1);
      Total();
    }
  }

  function AddAdicional(valor) {
    if (parseFloat(valor) > 0) {
      adc = adc + valor;

      setValorAdicional(adc);
      Total();
    }
  }

  function RemoveAdicional(valor) {
    if (parseFloat(valor) > 0) {
      adc = adc - valor;

      setValorAdicional(adc);
      Total();
    }
  }

  useEffect(() => {
    API.get("/v1/categoria/all").then(function (dados) {
      SetCategoria(dados.data);
      GravaLocalJson("Categorias", dados.data);
      SetCategoriaSelecionada(dados.data[0].codigo);
    });

    API.get(`v1/produto/categoria/${0}`).then(function (dados) {
      setAllProduct(dados.data);
      GravaLocalJson("AllProdutos", dados.data);
      //console.log(dados.data);
    });
  }, []);

  useEffect(() => {
    Total();
  }, [QuantidadeAtual]);

  useEffect(() => {
    GravaLocal("CategoriaSelecionada", categoriaSelecionada);
    if (categoriaSelecionada > 0) {
      API.get(`v1/produto/categoria/${categoriaSelecionada}`).then(function (
        dados
      ) {
        SetProdutosCategoria(dados.data);
        GravaLocalJson("ProdutosCategorias", dados.data);
        DadosAtuais = dados.data;
      });
    }
  }, [categoriaSelecionada]);

  useEffect(() => {
    const results = AllProduct.filter(
      (product) =>
        product.name.toLowerCase().includes(Input.toLowerCase()) ||
        product.description.toLowerCase().includes(Input.toLowerCase())
    );
    //console.log(results);

    if (Input.length == 0) {
      SetProdutosCategoria(DadosAtuais);
    } else {
      SetProdutosCategoria(results);
    }
    // DadosAtuais
  }, [Input]);

  useEffect(() => {
    if (DadosProdutoSelecionado.id > 0) {
      API.get(`v1/produtos/sabores/${DadosProdutoSelecionado.id}`).then(
        function (dados) {
          setDadosSabores(dados.data);
          //console.log(dados.data);
        }
      );
      setloading(true);
      API.get(`v1/produtos/adicional/${DadosProdutoSelecionado.id}`).then(
        function (dados) {
          const groupedData = dados.data.reduce((acc, item) => {
            if (!acc[item.categoria]) {
              acc[item.categoria] = {
                categoria: item.categoria,
                minino: item.min,
                maximo: item.max,
                atual: 0,
                registros: [],
              };
            }
            acc[item.categoria].registros.push(item);
            return acc;
          }, {});
          //console.log(groupedData);
          setDadosAdicionasiProdutoSelecionado(groupedData);
          setloading(false);
        }
      );
    }
  }, [DadosProdutoSelecionado]);

  const navigate = useNavigate();

  async function AdicionaProduto() {
    if (QuantidadeAtual == 0) {
      NotificaWarning(`Quantidade atual inválida!`);
      return false;
    }
    setloading(true);

    const response = await API.get(
      `v2/estoque/produto/insulmo/1/${ProdutoSelecionado}`
    );

    let estoque = response.data[0]?.estoque ? response.data[0].estoque : 0;

    if (DadosProdutoSelecionado.usaStock == 1) {
      if (QuantidadeAtual > estoque) {
        setloading(false);
        NotificaWarning(
          `Estoque atual é ${estoque}, e o total selecionado é de ${QuantidadeAtual}, você pode selecionar no máximo ${estoque}!`
        );
        return false;
      }
    }

    const chaves = Object.keys(DadosSelecionado);
    const resultado = [];

    const SaboresSelecionadosPizza = SaboresSelecionado();

    if (SaboresSelecionadosPizza == false) {
      setloading(false);
      NotificaWarning(
        `Deve-se selecionar ${DadosSabores[0].quantidadeSabores} sabores!`
      );
      return false;
    }

    chaves.forEach((chave) => {
      const valor = DadosSelecionado[chave];
      const repeticoes = new Array(valor).fill(chave);
      resultado.push(...repeticoes);
    });

    const stringSeparadaPorVirgula = resultado.join(",");

    var body = [
      {
        mesa: mesa || 0,
        produto: ProdutoSelecionado,
        qtd:
          QuantidadeAtual != 0
            ? parseFloat(substituirVirgulaPorPonto(QuantidadeAtual))
            : 1,
        adicionais: stringSeparadaPorVirgula,
        pizza: SaboresSelecionadosPizza,
        observacao: text,
        pedido: parseInt(pedido),
        valorProduto: tot,
      },
    ];

    API.post(`/v1/pedido/produto/${getCodigoUsuario()}`, body)
      .then((response) => {
        DadosSelecionado = {};
        navigate(BuscaLocal("VOLTAR"));
        setloading(false);
        Notifica("Produto adicionado com sucesso");
        setDadosAdicionasiProdutoSelecionado([]);
      })
      .catch((error) => {
        alert(error);
        // navigate(BuscaLocal("VOLTAR"));
        setloading(false);
      });
  }

  function SaboresSelecionado() {
    let codigo = "";
    let qtdSabores = 0;

    if (DadosSabores.length === 0) {
      return null;
    }

    Object.entries(DadosPizza).forEach(([chave, valor]) => {
      for (let i = 0; i < valor; i++) {
        if (codigo != "") {
          codigo = codigo + "," + chave;
          qtdSabores++;
        } else {
          codigo = chave;
          qtdSabores++;
        }
      }
    });

    if (DadosSabores[0].tipoPreco == 2) {
      if (qtdSabores != DadosSabores[0].quantidadeSabores) {
        return false;
      }
    }

    return codigo;

    //DadosPizza
  }

  return (
    <>
      {loading && <LoadingOverlay />}
      {ProdutoSelecionado == 0 ? (
        <>
          <HeaderComponent>
            {/* <InputPedido Valor={Input} Set={SetInput} Change={false} /> */}
            <TextField
              size="small"
              type={"text"}
              fullWidth
              label={""}
              value={Input}
              onChange={(e) => SetInput(e.target.value)}
              // onBlur={(e) => SetInput(e.target.value)}
              // sx={props.sx ? props.sx : null}
            />

            {Input.length > 0 ? (
              <div>
                {produtosCategoria.map((produto, index) => (
                  <ListProduct
                    key={index}
                    data={produto}
                    product={SelectProduct}
                    status={ProductStatusSelected}
                    getObs={ProductObsSelected}
                    obs={ObsProductProduct}
                    quantidade={ProductSelectedQuant}
                    add={ProdutctSelectedAdd}
                    remove={ProdutctSelectedRemove}
                    setAllProduct={setAllProduct}
                    clique={() => (
                      (qtd = 1),
                      setQuantidadeAtual(1),
                      SetProdutoSelecionado(produto.id),
                      SetDadosProdutoSele(produto),
                      (tot = parseFloat(produto.value)),
                      setValorTotal(produto.value),
                      Total()
                    )}
                  />
                ))}
              </div>
            ) : (
              <>
                <div className="lista-categorias" style={{ marginTop: "5px" }}>
                  <ul>
                    {categoria.map((categoria, index) =>
                      categoriaSelecionada == categoria.codigo ? (
                        <ListItem
                          key={index}
                          text={PrimeiraMaiuscula(categoria.descricao)}
                          id={categoria.codigo}
                          onClick={() =>
                            SetCategoriaSelecionada(categoria.codigo)
                          }
                          style={{
                            backgroundColor: "rgb(194, 0, 29)",
                            color: "white",
                          }}
                        />
                      ) : (
                        <ListItem
                          key={index}
                          text={PrimeiraMaiuscula(categoria.descricao)}
                          id={categoria.codigo}
                          onClick={() =>
                            SetCategoriaSelecionada(categoria.codigo)
                          }
                        />
                      )
                    )}
                  </ul>
                </div>
                <div
                  style={{
                    marginBottom: ProductSelected.length > 0 ? "55px" : "0px",
                  }}
                >
                  {produtosCategoria.map((produto, index) => (
                    <ListProduct
                      key={index}
                      data={produto}
                      product={SelectProduct}
                      obs={ObsProductProduct}
                      status={ProductStatusSelected}
                      getObs={ProductObsSelected}
                      quantidade={ProductSelectedQuant}
                      add={ProdutctSelectedAdd}
                      remove={ProdutctSelectedRemove}
                      clique={() => (
                        (qtd = 1),
                        setQuantidadeAtual(1),
                        SetProdutoSelecionado(produto.id),
                        SetDadosProdutoSele(produto),
                        (tot = parseFloat(produto.value)),
                        setValorTotal(produto.value),
                        Total()
                      )}
                    />
                  ))}
                </div>
              </>
            )}

            {ProductSelected.length > 0 ? (
              <>
                <div
                  style={{
                    position: "fixed",
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    background: "white",
                    marginBottom: "10px",
                    backgroundColor: "transparent",
                    // marginRight: "20px",
                    width: "100%", // Certifique-se de ocupar toda a largura
                    zIndex: 999, // Defina um valor alto para garantir que fique sobreposto a outras divs
                  }}
                >
                  <Button
                    descricao={
                      "Adicionar (" +
                      QuantidadeTotalSelecionada() +
                      ") " +
                      MascaraMonetaria(TotalSelecionada())
                    }
                    color="#A8001C"
                    evento={EnvarMaisDeUmProduto}
                    sx={{ marginRight: "20px", width: "100%" }}
                  />
                </div>
              </>
            ) : null}
          </HeaderComponent>
        </>
      ) : (
        <>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: "30px",
              height: "50px",
              color: "#A8001C",
            }}
          >
            <div
              style={{ position: "absolute", left: 0, marginLeft: "10px" }}
              onClick={() => (
                SetProdutoSelecionado(0),
                SetDadosProdutoSele([]),
                setDadosAdicionasiProdutoSelecionado([]),
                SelecionouProduto()
              )}
            >
              <FaArrowLeft size={24} />
            </div>
            <div style={{ textAlign: "center" }}>
              <span style={{ fontSize: "18px" }}>
                {PrimeiraMaiuscula(DadosProdutoSelecionado.name)}
              </span>
            </div>
          </div>

          {DadosAdicionasiProdutoSelecionado ? (
            <>
              <AdicionaComponent
                dados={DadosAdicionasiProdutoSelecionado}
                add={AddAdicional}
                remove={RemoveAdicional}
                atualizadados={DadosSelecionadoQueForam}
              />
            </>
          ) : null}

          {DadosSabores.length > 0 ? (
            <>
              <PizzaComponent
                dados={DadosSabores}
                add={AddAdicional}
                remove={RemoveAdicional}
                atualizadados={DadosSelecionadoQueForamPizza}
              />
            </>
          ) : null}

          <div style={{ padding: "10px", marginBottom: "100px" }}>
            <div>Observação</div>
            <textarea
              value={text}
              onChange={handleChange}
              maxLength={200}
              style={{ width: "100%", height: "100px", border: "1px solid" }}
            />
            <div>Carácteres restantes {remainingCharacters}</div>
          </div>

          <div
            style={{
              position: "fixed",
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              background: "white",
              margin: "10px",
              width: "100%", // Certifique-se de ocupar toda a largura
              zIndex: 999, // Defina um valor alto para garantir que fique sobreposto a outras divs
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                background: "#ffffff",

                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  justifyItems: "center",
                  width: "100%",
                }}
              >
                <Button
                  descricao={`Adicionar ${MascaraMonetaria(Totalizador)}`}
                  color="#A8001C"
                  evento={AdicionaProduto}
                  sx={{ width: "100%", marginRight: "10px", height: "48px" }}
                />
              </div>

              <IconButton
                color="#F0BA18"
                sx={{
                  width: "48px",
                  height: "48px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "15px",
                  color: "#F0BA18",
                  border: "1px solid #F0BA18",
                }}
                onClick={() => QtdRemove()}
              >
                <MdRemove size={24} />
              </IconButton>

              <input
                style={{
                  height: "48px",
                  width: "78px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "white",
                  textAlign: "center",
                }}
                value={QuantidadeAtual}
                onChange={(e) => setQuantidadeAtual(e.target.value)}
              ></input>
              <IconButton
                color="#269931"
                sx={{
                  width: "48px",
                  height: "48px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "15px",
                  color: "#269931",
                  border: "1px solid #269931",
                }}
                onClick={() => QtdAdd()}
              >
                <MdAdd size={24} />
              </IconButton>
            </div>
          </div>
        </>
      )}
    </>
  );
}
