import { useEffect, useState } from "react";
import { HeaderComponent } from "../../component/header";
import { useParams } from "react-router-dom";
import { API } from "../../controller/api/api";
import {
  AbrirCaixaAutomatico,
  MascaraMonetaria,
  RemoveMascara,
} from "../../controller/data";

import { ApagaLocal, BuscaLocal, GravaLocal } from "../../controller/storage";

import {
  GetCache,
  MascaraCelular,
  MonitorSmall,
  Notifica,
  NotificaWarning,
  PrimeiraMaiuscula,
} from "../../controller/util";
import { Button } from "../../component/button";
import { InputPedido } from "../pedido/componente/input";
import { isMobileDevice } from "../../controller/mobile";
import {
  FaSearch,
  FaTrash,
  FaMoneyBill,
  FaCreditCard,
  FaFileInvoice,
  FaQuestion,
} from "react-icons/fa";
import { ParametroConfiguracao } from "../../controller/parametros";
import { ComponentePIX } from "./component/pix";
import { UserPermissionFinalizar } from "../../controller/user";
import "./style.css";
import { Toggle } from "../../component/toggle";
import { ComponentBuscaCliente } from "./component/busca";
import LoadingOverlay from "../../component/load";
import { MdAdd, MdRemove } from "react-icons/md";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { CarregaProdutos } from "../salaopage/componente/transferencia/component/CardProduct";
import {
  BuscarNovoValor,
  loadData,
  PegouDados,
  RemoverSelected,
  summarize,
} from "../salaopage/componente/transferencia/component/controller";

let codigoPagamento = 0;
let Pedido = 73950;
let TaxServico = false;
let ocultarTopo = false;
let DadosPagamentoSelecionado = [];
let ValorImputPago = 0;

export function FechamentoPages({ PedidoID, closeButton }) {
  const { id } = useParams();
  Pedido = PedidoID ? PedidoID : id;
  ocultarTopo = PedidoID ? true : false;
  const [TipoPagamento, setTipoPagamento] = useState([]);
  const [Pagamento, setPagamento] = useState(
    BuscaLocal("pag_select") ? parseInt(BuscaLocal("pag_select")) : 0
  );
  const [codigosSelecionados, setCodigosSelecionados] = useState([]);
  const [TotalSelecionados, setTotalSelecionados] = useState(0);
  const [Dados, setDados] = useState([]);

  const [PagamentosInformado, setPagamentoInformado] = useState([]);
  const [LoadProduto, setLoadProduto] = useState(false);

  const [TotalInformao, setTotalInformao] = useState(0);
  const [TotalPessoa, setTotalPessoa] = useState(1);

  const [TotalPedido, setTotalPedido] = useState(0);
  const [TotalPago, setTotalPago] = useState(0);
  const [TotalDiferenca, setTotalDiferenca] = useState(0);

  const [DadosPedidoPrincipal, setDadosPedidoPrincipal] = useState([]);
  const [DescricaoPedido, setDescricaoPedido] = useState("");

  const [NumeroCaixa, setNumeroCaixa] = useState(0);

  const [PedidoJaFaturado, setPedidoJaFaturado] = useState(false);
  const [isPix, setIsPix] = useState(false);
  const [Base64, setBase64] = useState("");
  const [LinhaDigitavel, setLinhaDigitavel] = useState("");
  const [TotalPix, setTotalPix] = useState(0);
  const [ConsultaPIX, setConsultaPIX] = useState("");
  const [taxaServico, setTaxaServico] = useState(0);
  const [Servico, setServico] = useState(false);

  const [UtilizaNFCe, setUtlizaNFCe] = useState(false);
  const [CPF, setCPF] = useState("");
  const [Nome, setNome] = useState("");

  const [loading, setloading] = useState(false);

  const [altPressed, setAltPressed] = useState(false);
  const [TipoPessoa, setTipoPessoa] = useState(1);
  const [ImpressaoNFCe, setImpressaoNFCe] = useState(
    BuscaLocal("impressao_nfce") ? parseInt(BuscaLocal("impressao_nfce")) : 1
  );

  const [DadosMotoboy, setDadosMotoboy] = useState([]);
  const [IdMotoboy, setIdMotoboy] = useState(0);

  const [isViweProdutos, setIsViweProdutos] = useState(false);

  const [PagamentoSelecionado, setPagamentoSelecionado] = useState(
    DadosPagamentoSelecionado
  );

  function manterApenasNumeros(texto) {
    return texto.replace(/\D/g, "");
  }

  const Card = ({ Codigo, Produto, Obs, Quantidade, Total }) => {
    let valorTotal = Total / Quantidade;

    const [QuantidadeSelecionada, setQuantidadeSelecionada] =
      useState(Quantidade);

    const handleCheckboxChange = (e, total) => {
      const posicao = codigosSelecionados.indexOf(e);

      if (posicao !== -1) {
        // Desmarcar o checkbox
        // Remover o código do objeto
        setCodigosSelecionados(
          codigosSelecionados.filter((codigo) => codigo !== e)
        );
        setTotalSelecionados(TotalSelecionados - total);
        console.log("1");
        setTotalInformao(
          MascaraMonetaria((TotalSelecionados - total) / TotalPessoa)
        );
      } else {
        // Marcar o checkbox
        // Adicionar o código ao objeto
        setCodigosSelecionados([...codigosSelecionados, e]);
        setTotalSelecionados(TotalSelecionados + total);
        console.log("2");
        setTotalInformao(
          MascaraMonetaria((TotalSelecionados + total) / TotalPessoa)
        );
      }

      // setChecked(!checked);
      var aux = Dados;
      setDados([]);
      setDados(aux);
    };

    // const AddRemove = (e, total) => {
    //   const posicao = codigosSelecionados.indexOf(e);

    //   setTotalSelecionados(TotalSelecionados + total);
    //   setTotalInformao(
    //     MascaraMonetaria((TotalSelecionados + total) / TotalPessoa)
    //   );
    // };

    const AddRemove = (total, quantidade, totalSelecionados, totalPessoa) => {
      const novoTotalSelecionados = totalSelecionados + total;
      const novoTotalInformao = MascaraMonetaria(
        (totalSelecionados + total) / totalPessoa
      );

      return { novoTotalSelecionados, novoTotalInformao };
    };

    function Add() {
      if (QuantidadeSelecionada === Quantidade) {
        return;
      } else {
        setQuantidadeSelecionada(QuantidadeSelecionada + 1);
        const { novoTotalSelecionados, novoTotalInformao } = AddRemove(
          valorTotal,
          QuantidadeSelecionada + 1,
          TotalSelecionados,
          TotalPessoa
        );
        setTotalSelecionados(novoTotalSelecionados);
        setTotalInformao(novoTotalInformao);
        console.log("3");
      }
    }

    function Remove() {
      if (QuantidadeSelecionada === 0) {
        return;
      } else {
        setQuantidadeSelecionada(QuantidadeSelecionada - 1);
        const { novoTotalSelecionados, novoTotalInformao } = AddRemove(
          -valorTotal,
          QuantidadeSelecionada - 1,
          TotalSelecionados,
          TotalPessoa
        );
        setTotalSelecionados(novoTotalSelecionados);
        setTotalInformao(novoTotalInformao);
        console.log("4");
      }
    }

    return (
      <div
        key={Codigo}
        style={{
          display: "flex",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          marginBottom: "10px",
          padding: "10px",
          backgroundColor: "#ffffff",
          transition: "box-shadow 0.3s ease-in-out",
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.boxShadow = "0 8px 12px rgba(0, 0, 0, 0.1)")
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)")
        }
      >
        <div
          style={{
            height: "24px",
            width: "24px",
            margin: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid black",
            borderRadius: "50px",
            backgroundColor:
              codigosSelecionados.indexOf(Codigo) !== -1
                ? "black"
                : "transparent",
          }}
          onClick={(e) => handleCheckboxChange(Codigo, Total)}
        ></div>

        <div style={{ height: "100%", width: "80%" }}>
          <h4 style={{ fontSize: "1.1rem" }}>
            <b>{PrimeiraMaiuscula(Produto)}</b>
          </h4>
          <h4>
            {PrimeiraMaiuscula(Obs) == "Observação"
              ? PrimeiraMaiuscula(Obs)
              : ""}
          </h4>

          <div
            style={{
              display: "flex",
              // alignContent: "center",
              // justifyItems: "center",
              // textAlign: "center",
            }}
          >
            {codigosSelecionados.indexOf(Codigo) !== -1 ? (
              <>
                <div style={{ display: "flex", flex: "0 0 auto" }}>
                  <button
                    style={{
                      backgroundColor: Quantidade == 1 ? "#808080" : "#F0BA18",
                      color: "white",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={Remove}
                  >
                    <MdRemove size={24} />
                  </button>

                  <input
                    style={{
                      height: "100%",
                      width: "48px",
                      display: "flex",

                      alignItems: "center",
                      justifyContent: "center",
                      background: "white",
                      textAlign: "center",
                    }}
                    value={QuantidadeSelecionada}
                    // onChange={(e) => setQuantidadeAtual(e.target.value)}
                  ></input>
                  <button
                    style={{
                      backgroundColor: "#269931",
                      color: "white",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50px",
                      marginRight: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={Add}
                  >
                    <MdAdd size={24} />
                  </button>
                </div>
              </>
            ) : null}

            <h1 style={{ display: "flex", alignItems: "center" }}>
              {MascaraMonetaria((Total / Quantidade) * QuantidadeSelecionada)}
            </h1>
          </div>
        </div>
      </div>
    );
  };

  const handleDeleteItem = (index, Codigo) => {
    setPagamentoInformado((prevPagamentos) => {
      setLoadProduto(false);
      API.delete(`/v1/pedido/pagamento/delete/movimentacao/${Codigo}`).then(
        function (response) {
          setLoadProduto(true);
        }
      );

      // Certifique-se de que o índice esteja dentro dos limites do array
      if (index >= 0 && index < prevPagamentos.length) {
        const novoPagamentos = [...prevPagamentos];
        novoPagamentos.splice(index, 1); // Remove o item com o índice especificado
        return novoPagamentos;
      }
      return prevPagamentos; // Caso o índice esteja fora dos limites, não faça alterações
    });
  };

  const CardPagamento = ({ Codigo, Descricao, Valor, Dados, index }) => {
    const [hovered, setHovered] = useState(false);
    const [BuscarCliente, setBuscarCliente] = useState(false);

    const handleMouseEnter = () => {
      setHovered(true);
    };

    const handleMouseLeave = () => {
      setHovered(false);
    };

    const removeAccents = (str) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const getIconByPaymentType = (type) => {
      if (!type) {
        return <FaQuestion size={24} />;
      }
      const normalizedType = removeAccents(type.toLowerCase());

      if (normalizedType.includes("pix")) {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="24"
            height="24"
            viewBox="0 0 48 48"
          >
            <path
              fill="#4db6ac"
              d="M11.9,12h-0.68l8.04-8.04c2.62-2.61,6.86-2.61,9.48,0L36.78,12H36.1c-1.6,0-3.11,0.62-4.24,1.76	l-6.8,6.77c-0.59,0.59-1.53,0.59-2.12,0l-6.8-6.77C15.01,12.62,13.5,12,11.9,12z"
            ></path>
            <path
              fill="#4db6ac"
              d="M36.1,36h0.68l-8.04,8.04c-2.62,2.61-6.86,2.61-9.48,0L11.22,36h0.68c1.6,0,3.11-0.62,4.24-1.76	l6.8-6.77c0.59-0.59,1.53-0.59,2.12,0l6.8,6.77C32.99,35.38,34.5,36,36.1,36z"
            ></path>
            <path
              fill="#4db6ac"
              d="M44.04,28.74L38.78,34H36.1c-1.07,0-2.07-0.42-2.83-1.17l-6.8-6.78c-1.36-1.36-3.58-1.36-4.94,0	l-6.8,6.78C13.97,33.58,12.97,34,11.9,34H9.22l-5.26-5.26c-2.61-2.62-2.61-6.86,0-9.48L9.22,14h2.68c1.07,0,2.07,0.42,2.83,1.17	l6.8,6.78c0.68,0.68,1.58,1.02,2.47,1.02s1.79-0.34,2.47-1.02l6.8-6.78C34.03,14.42,35.03,14,36.1,14h2.68l5.26,5.26	C46.65,21.88,46.65,26.12,44.04,28.74z"
            ></path>
          </svg>
        );
      } else if (normalizedType.includes("dinheiro")) {
        return <FaMoneyBill size={24} />;
      } else if (
        normalizedType.includes("cartao") ||
        normalizedType.includes("cartão") ||
        normalizedType.includes("credito") ||
        normalizedType.includes("crédito") ||
        normalizedType.includes("debito") ||
        normalizedType.includes("débito")
      ) {
        return <FaCreditCard size={24} />;
      } else if (normalizedType.includes("fiado")) {
        return <FaFileInvoice size={24} />;
      } else {
        return <FaQuestion size={24} />;
      }
    };

    return (
      <div
        key={index}
        style={{
          display: "flex",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          marginBottom: "10px",
          padding: "10px",
          backgroundColor: "#ffffff",
          transition: "box-shadow 0.3s ease-in-out",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {getIconByPaymentType(Descricao)}
          <div style={{ height: "100%", width: "80%", marginLeft: "10px" }}>
            <h4>
              <b>{PrimeiraMaiuscula(Descricao)}</b>
            </h4>
            {Dados.tipo === 2 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: Dados?.nome
                    ? hovered
                      ? "rgb(168, 0, 28)"
                      : "black"
                    : "rgb(168, 0, 28)",
                  cursor: "pointer",
                }}
              >
                <FaSearch
                  size={14}
                  style={{
                    marginRight: "5px",
                    color: hovered ? "rgb(168, 0, 28)" : "black",
                  }}
                />
                <h3>
                  <b onClick={() => setBuscarCliente(!BuscarCliente)}>
                    {Dados?.nome
                      ? `${Dados.cpf} - ${PrimeiraMaiuscula(
                          Dados.nome
                        )}  ${MascaraCelular(Dados.celular)}`
                      : "Buscar Cliente"}
                  </b>
                </h3>
              </div>
            ) : null}
            <h4 style={{ color: "#269931" }}>
              <b>{MascaraMonetaria(Valor)}</b>
            </h4>
          </div>
        </div>
        <FaTrash
          size={24}
          style={{
            cursor: "pointer",
            color: hovered ? "rgb(168, 0, 28)" : "black",
          }}
          className="trash-icon"
          onClick={() => handleDeleteItem(index, Codigo)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        {BuscarCliente ? (
          <ComponentBuscaCliente
            Close={() => setBuscarCliente(false)}
            index={index}
            Cliente={RecebeClientePagamento}
          />
        ) : null}
      </div>
    );
  };

  function RecebeClientePagamento(dados, index) {
    const registroIndex = index; // Índice do registro específico que você deseja atualizar

    setPagamentoInformado((prevPagamentos) => {
      // Faça uma cópia profunda do array de pagamentos
      const updatedPagamentos = [...prevPagamentos];

      if (registroIndex < updatedPagamentos.length) {
        updatedPagamentos[registroIndex] = {
          ...updatedPagamentos[registroIndex],
          nome: dados.nome,
          cpf: dados.cpf,
          celular: dados.celular,
        };
      }

      return updatedPagamentos;
    });
  }

  function SetValorPagamento(valor) {
    var input = document.querySelector(`input[name=total_informado]`);

    if (input) {
      ValorImputPago = MascaraMonetaria(valor);
      console.log(ValorImputPago);
      input.value = ValorImputPago;
    }
  }

  function CalculaTotal() {
    let valor = 0;
    PagamentosInformado.forEach((item, index) => {
      valor += item.valor;
    });
    setTotalPago(parseFloat(valor));

    let resto =
      RemoveMascara(CalculaTaxaServico(TotalPedido)) - truncarDuasCasas(valor);

    if (resto > 0) {
      SetValorPagamento(resto);
    }

    setTotalDiferenca(RemoveMascara(resto.toFixed(2)));
  }

  function truncarDuasCasas(num) {
    return Number(num.toFixed(2));
  }

  function CalculaTaxaServico(Valor) {
    if (Servico) {
      let taxa = truncarDuasCasas((Valor * taxaServico) / 100);

      return truncarDuasCasas(taxa + Valor);
    } else {
      return truncarDuasCasas(Valor);
    }
  }

  useEffect(() => {
    CalculaTotal();
  }, [PagamentosInformado, TotalDiferenca]);

  useEffect(() => {
    if (CPF) {
      let documento = manterApenasNumeros(CPF);
      if (documento.length == 11) {
        API.get(`/v2/consulta/cpf/${CPF}`).then((response) => {
          if (response.data.length > 0) {
            setNome(response.data[0].nome);
          } else {
            setNome("");
          }
        });
      }
      if (documento.length == 14) {
        API.get(`/v2/cnpj/${documento}`).then((response) => {
          setNome(PrimeiraMaiuscula(response.data.nome));
        });
      }
    }
  }, [CPF]);

  useEffect(() => {
    AbrirCaixaAutomatico()
      .then((result) => {
        //console.log(result);
        setNumeroCaixa(result.id);
      })
      .catch((error) => {});
    const intervalId = setInterval(() => {
      let TotalAjustado = summarize();

      if (BuscarNovoValor()) {
        SetValorPagamento(TotalAjustado.total);
        PegouDados();
      }
    }, 1000);

    GetCache("v1/tipo/pagamento/", 5).then(function (dados) {
      setTipoPagamento(dados);
    });

    API.get("v1/dados/pedido/" + Pedido).then(function (dados) {
      setDadosPedidoPrincipal(dados.data);

      if (dados.data?.codigoClienteEndereco > 0) {
        GetCache("/v1/motoboy/ativo/all/", 5).then((response) => {
          setDadosMotoboy(response);
        });

        API.get(
          `/v1/consulta/generica/pedido_motoboy/*/codigo_pedido = ${Pedido}/*`
        ).then((response) => {
          if (response.data[0]) {
            setIdMotoboy(response.data[0].codigoMotoboy);
          }
        });
        //select * from pedido_motoboy where codigo_pedido = 1
      }

      setCPF(dados.data?.cpf ? dados.data.cpf : "");
      setNome(dados.data?.nome ? dados.data.nome : "");

      if (dados.data.idFicha > 0) {
        setDescricaoPedido(dados.data.descFicha);
        setIsViweProdutos(true);
        //console.log(dados.data.idFicha);
      } else {
        setDescricaoPedido("Pedido Nº " + dados.data.codigoPedidoDia);
        setTotalPedido(dados.data.valorTotalPedido);
      }
    });

    CarregaPagamentos();

    Parametros();
    return () => {
      // Esta função de limpeza será executada quando o componente for desmontado
      RemoverSelected();
      clearInterval(intervalId);
      // Coloque aqui o código que você deseja executar quando o usuário sair da tela
      // Por exemplo, você pode fazer uma chamada API para salvar dados ou liberar recursos
      // AtualizaParametro("mensagem_inicio", text);
    };
  }, []);

  function CarregaPagamentos() {
    BuscarDados();
    setLoadProduto(false);
    API.get(`/v1/pedido/pagamento/${Pedido}`).then(function (dados) {
      setPagamentoInformado([]);

      for (const elemento of dados.data) {
        codigoPagamento++;
        setPagamentoInformado((prevPagamentos) => {
          const novoPagamentos = [
            ...prevPagamentos,
            {
              id: elemento.id,
              idTipoPagamento: elemento.idTipoPagamento,
              descricaoTipoPag: PrimeiraMaiuscula(elemento.descricao1),
              valor: truncarDuasCasas(RemoveMascara(elemento.valor)),
              transacaoMp: elemento.id,
              tipo: elemento.movimentacao,
            },
          ];
          return novoPagamentos;
        });
      }
      setLoadProduto(true);
    });
  }

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        if (TotalDiferenca == 0) {
          setAltPressed(true);
        }
      }
    }

    function handleKeyUp(event) {
      if (event.key === "Escape") {
        if (TotalDiferenca == 0) {
          setAltPressed(false);
        }
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  async function Parametros() {
    let servico = await ParametroConfiguracao("taxaServicoPedido");

    if (servico > 0) {
      TaxServico = true;
      setServico(true);
      setTaxaServico(servico);
    }

    let UsaNFCe = await ParametroConfiguracao("nfce");
    setUtlizaNFCe(UsaNFCe == 1);
  }

  useEffect(() => {
    //console.log(DadosPedidoPrincipal);
    BuscarDados();
  }, [DadosPedidoPrincipal]);

  function BuscarDados() {
    API.get("/v1/pedido/produtos/" + Pedido).then(function (dados) {
      let tot = 0;
      setDados(dados.data);

      if (DadosPedidoPrincipal.idFicha > 0) {
        for (const elemento of dados.data) {
          tot += elemento.valorTotal;
        }
        setTotalPedido(tot);
      }
    });
  }

  useEffect(() => {
    CalculaTotal();
  }, [TotalPedido]);

  function PixRecebido(transacao) {
    ///v1/pedido/pagamento/pix/:caixa/:pedido/:tipo/:total

    let Pagamento = BuscaLocal("codigo_pagamento");

    API.put(
      `/v1/pedido/pagamento/pix/${NumeroCaixa}/${Pedido}/${
        TipoPagamento[Pagamento].codigo
      }/${RemoveMascara(TotalInformao)}`
    );
    codigoPagamento++;
    setPagamentoInformado((prevPagamentos) => {
      const novoPagamentos = [
        ...prevPagamentos,
        {
          id: 0,
          idTipoPagamento: TipoPagamento[Pagamento].codigo,
          descricaoTipoPag: TipoPagamento[Pagamento].descricao,
          valor: RemoveMascara(TotalInformao),
          transacaoMp: transacao,
          tipo: TipoPagamento[Pagamento].movimentacao,
        },
      ];
      return novoPagamentos;
    });
    setTotalInformao(0);
    console.log("6");
    setIsPix(false);
  }

  async function AdicionaPagamento() {
    var input2 = document.querySelector(`input[name=forma-pagamento]`);
    let Pagamento = input2.value;

    GravaLocal("codigo_pagamento", Pagamento);

    if (input2.value == "") {
      NotificaWarning("Nenhuma forma de pagamento selecionada!");
      return false;
    }
    const descricaoLowerCase = TipoPagamento[Pagamento].descricao.toLowerCase();
    let pix = descricaoLowerCase.includes("pix");
    //console.log(descricaoLowerCase);

    var input = document.querySelector(`input[name=total_informado]`);
    var ValorInformao = RemoveMascara(input.value);
    let DadosSelecionados = loadData();
    console.log("CU");

    if (parseFloat(ValorInformao) < 1) {
      NotificaWarning("Valor deve-se ser informado!");
      return false;
    }

    //buscar Dados Configurações
    //Validar Se o pagamento é Pix e se tem configurado os dados para o pix
    //console.log(pix);
    if (ValorInformao != 0) {
      if (pix) {
        let token = await ParametroConfiguracao("tokenMp");
        let integracao = await ParametroConfiguracao("fatIntegraPix");

        let url = `/v1/gera/pix/${token}/${RemoveMascara(
          ValorInformao
        )}/${Pedido}`;

        if (token.length > 10 && integracao == 1) {
          await API.post(url).then(function (dados) {
            setBase64(dados.data.base64);
            setLinhaDigitavel(dados.data.qrcod);
            setTotalPix(MascaraMonetaria(RemoveMascara(ValorInformao)));
            setConsultaPIX(url);
            setIsPix(true);
          });
          return;
        }
      }

      API.put(
        `/v1/pedido/pagamento/${NumeroCaixa}/${Pedido}/${
          TipoPagamento[Pagamento].codigo
        }/${RemoveMascara(ValorInformao)}`
      ).then(function (response) {
        API.post(
          `/v2/pagamento/produtos/${response.data.codigo}`,
          DadosSelecionados
        ).then(function (response) {
          CarregaPagamentos();
        });
      });

      codigoPagamento++;

      ApagaLocal("pag_select");
      RemoverSelected();
      setTotalInformao(0);
      console.log("7");
    }
  }

  const Pagamentos = () => {
    return (
      <>
        {PagamentosInformado.map((dados, index) => (
          <CardPagamento
            key={index}
            index={index}
            Codigo={dados.id}
            Descricao={dados.descricaoTipoPag}
            Valor={dados.valor}
            Dados={dados}
          ></CardPagamento>
        ))}
        {/* DadosPedidoPrincipal */}
      </>
    );
  };

  function AlteraValoresInput(type, valor) {
    if (type == "Dividido Por") {
      setTotalPessoa(valor);
      if (valor > 0) {
        setTotalInformao(MascaraMonetaria(TotalSelecionados / valor));
        console.log("8");
      }
    }
  }

  function FinalizaPagamento() {
    if (!UserPermissionFinalizar()) {
      NotificaWarning("Usuário sem permissão para finalizar!");
      setloading(false);
      return;
    }
    setloading(true);
    let url = "";

    if (DadosPedidoPrincipal.idFicha > 0) {
      let acrecimo = RemoveMascara(
        (CalculaTaxaServico(TotalPedido) - TotalPedido).toFixed(2)
      );
      acrecimo = Math.trunc(acrecimo * 100) / 100;
      acrecimo = acrecimo.toString().replace(".", ",");
      if (!Servico) {
        acrecimo = 0;
      }

      url = `/v1/pedido/finaliza/${DadosPedidoPrincipal.idFicha}/${ImpressaoNFCe}/0/${acrecimo}/0/0/${NumeroCaixa}/${Pedido}`;
    } else {
      url = `/v1/pedido/finaliza/${0}/${ImpressaoNFCe}/0/0/0/0/${NumeroCaixa}/${Pedido}`;
    }

    // ImpressaoNFCe

    // var input3 = document.querySelector(`input[name=motoboy]`);
    // let IdMotoboy = input3?.value ? input3?.value : 0;

    // //console.log(IdMotoboy);
    // return IdMotoboy;

    if (!altPressed) {
      url = url + `/1/${IdMotoboy}`;
    } else {
      url = url + `/0/${IdMotoboy}`;
    }

    let bodyDocumento = {
      cpfcnpj: manterApenasNumeros(CPF),
      nome: Nome.toUpperCase(),
      pedido: Pedido,
    };

    API.post("v2/nfce/dados/cpfcnpj", bodyDocumento);

    let Fiado = false;

    if (TotalDiferenca > 0) {
      NotificaWarning("Total informado não corresponde ao valor do pedido!");
      setloading(false);
      return false;
    } else {
      PagamentosInformado.forEach((pagamento, index) => {
        if (pagamento.tipo === 2) {
          if (!pagamento.nome || pagamento.nome.trim() === "") {
            NotificaWarning(
              `${pagamento.descricaoTipoPag} deve-se selecionar um cliente, para gerar o pagamento!`
            );
            Fiado = true;
            return false;
          }
        }
      });

      // return false;

      if (!Fiado) {
        API.put(url, PagamentosInformado).then(function (response) {
          Notifica("Pedido Faturado!");

          if (closeButton) {
            closeButton();
          } else {
            window.location.href = BuscaLocal("VOLTAR");
          }
        });
      }
    }
  }

  function AlteraValoresInputCPF(type, valor) {
    if (type == "CPF") {
      setCPF(valor);
    }
    if (type == "Nome") {
      setNome(valor);
    }
  }

  const CardFechamento = ({ isViweProdutos, setIsViweProdutos }) => {
    const [isViweDadosNFCe, setIsViweDadosNFCe] = useState(false);

    return (
      <>
        <div
          style={{
            display: isMobileDevice() ? "block" : "flex",
          }}
        >
          <div
            style={{
              flexWrap: "wrap",
              width: isMobileDevice()
                ? "100%"
                : MonitorSmall()
                ? "350px"
                : "20%",
              // display: "flex",
              // flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "52px",
                backgroundColor: "rgb(168, 0, 28)",
                color: "white",
                marginBottom: "10px",
                borderRadius: "8px", // Canto arredondado
                padding: "5px", // Espaçamento interno
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Sombra
                background:
                  "linear-gradient(135deg, rgb(168, 0, 28), rgb(200, 50, 50))", // Gradiente sutil
                display: "flex",
                alignItems: "center",
              }}
            >
              <h2 style={{ fontSize: "1.2rem" }}>
                {PrimeiraMaiuscula(DescricaoPedido)}
              </h2>
            </div>
            {TotalDiferenca > 0 ? (
              <>
                <FormControl fullWidth>
                  <InputLabel id="forma-pagamento">
                    Forma De Pagamento
                  </InputLabel>
                  <Select
                    labelId="forma-pagamento"
                    id="forma-pagamento"
                    name="forma-pagamento"
                    value={Pagamento}
                    label="Forma De Pagamento"
                    onChange={(e) => [
                      setPagamento(e.target.value),
                      GravaLocal("pag_select", e.target.value),
                    ]}
                    sx={{ width: "100%", marginBottom: "10px" }}
                    size="small"
                  >
                    {TipoPagamento.map((item, index) => (
                      <MenuItem key={index} value={index}>
                        {PrimeiraMaiuscula(item.descricao)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div style={{ marginBottom: "10px" }}>
                  <InputPedido
                    Descricao="Total R$"
                    Set={AlteraValorTotal}
                    // Retorno={AlteraValoresInput}
                    Valor={ValorImputPago}
                    name="total_informado"
                  />
                </div>
              </>
            ) : null}

            {DadosMotoboy.length > 0 && TotalDiferenca === 0 ? (
              <>
                <FormControl fullWidth sx={{ marginTop: "10px" }}>
                  <InputLabel id="select-label">Motoboy</InputLabel>
                  <Select
                    labelId="select-label"
                    label="Motoboy"
                    value={IdMotoboy}
                    id="motoboy"
                    name="motoboy"
                    size="small"
                    sx={{ marginBottom: "10px" }}
                    onChange={(e) => setIdMotoboy(e.target.value)}
                  >
                    <MenuItem value={0}>Sem Motoboy</MenuItem>
                    {DadosMotoboy.map((item, index) => (
                      <MenuItem key={index} value={item.codigo}>
                        {PrimeiraMaiuscula(item.nome)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : null}

            {PedidoJaFaturado == false ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",

                    marginBottom: "10px",
                    // height: "45px",
                    width: "100%",
                  }}
                >
                  {(NumeroCaixa > 0) & (TotalDiferenca > 0) ? (
                    <>
                      {" "}
                      <Button
                        descricao="Adicionar Pagamento"
                        evento={AdicionaPagamento}
                        color="#138138"
                        load={false}
                      />
                    </>
                  ) : null}
                </div>
                {UtilizaNFCe ? (
                  <>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(168, 0, 28)",
                        color: "white",
                        marginBottom: "10px",
                        borderRadius: "8px", // Canto arredondado
                        padding: "5px", // Espaçamento interno
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Sombra
                        background:
                          "linear-gradient(135deg, rgb(168, 0, 28), rgb(200, 50, 50))", // Gradiente sutil
                        display: "flex",
                        alignItems: "center", // Centraliza verticalmente o conteúdo
                        justifyContent: "center", // Centraliza horizontalmente o conteúdo
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <h2
                          style={{
                            fontSize: "1.2rem",
                            // margin: 0,
                            // textAlign: "center",
                            flex: 1, // Expande o h2 para ocupar espaço disponível
                          }}
                        >
                          Dados NFC-e
                        </h2>
                        <Checkbox
                          style={{
                            marginLeft: "auto",
                            color: "#fff",
                            "&.Mui-checked": {
                              color: "#fff",
                            },
                          }}
                          checked={isViweDadosNFCe}
                          onChange={() => setIsViweDadosNFCe(!isViweDadosNFCe)}
                        />
                      </div>
                    </div>

                    {isViweDadosNFCe ? (
                      <>
                        <div style={{ marginTop: "10px" }}>
                          <FormControl fullWidth>
                            <InputLabel id="tipo-pessoa">Tipo NFC-e</InputLabel>
                            <Select
                              labelId="tipo-pessoa"
                              id="tipo-pessoa"
                              value={TipoPessoa}
                              label="Tipo Pessoa (Física / Jurídica)"
                              onChange={(e) => setTipoPessoa(e.target.value)}
                              sx={{ width: "100%", marginBottom: "10px" }}
                              size="small"
                            >
                              <MenuItem key={1} value={1}>
                                Pessoa Física (CPF)
                              </MenuItem>
                              <MenuItem key={2} value={2}>
                                Pessoa Jurídica (CNPJ)
                              </MenuItem>
                            </Select>
                          </FormControl>

                          <div
                            style={{ flex: "1 0 100%", marginBottom: "10px" }}
                          >
                            <InputPedido
                              Descricao={TipoPessoa == 1 ? "CPF" : "CNPJ"}
                              // Retorno={AlteraValoresInputCPF}
                              Tipo={TipoPessoa == 1 ? "cpf" : "cnpj"}
                              Valor={CPF}
                              Set={setCPF}
                            />
                          </div>
                          <div
                            style={{ flex: "1 0 100%", marginBottom: "10px" }}
                          >
                            <InputPedido
                              Descricao={
                                TipoPessoa == 1 ? "Nome" : "Razão Social"
                              }
                              Retorno={AlteraValoresInputCPF}
                              Valor={PrimeiraMaiuscula(Nome)}
                            />
                          </div>

                          <FormControl fullWidth>
                            <InputLabel id="tipo-impressao">
                              Impressão NFC-e Automático
                            </InputLabel>
                            <Select
                              labelId="tipo-impressao"
                              id="tipo-impressao"
                              value={ImpressaoNFCe}
                              label="Impressão NFC-e Automático"
                              onChange={(e) => [
                                GravaLocal("impressao_nfce", e.target.value),
                                setImpressaoNFCe(e.target.value),
                              ]}
                              sx={{ width: "100%", marginBottom: "10px" }}
                              size="small"
                            >
                              <MenuItem key={1} value={1}>
                                Imprimir
                              </MenuItem>
                              <MenuItem key={2} value={2}>
                                Não Imprimir
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : (
              <>
                <div style={{ flex: "1 0 100%" }}>
                  <h6>Pedido já faturado!</h6>
                </div>
              </>
            )}

            {isMobileDevice() ? (
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(168, 0, 28)",
                    color: "white",
                    marginBottom: "10px",
                    borderRadius: "8px", // Canto arredondado
                    padding: "5px", // Espaçamento interno
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Sombra
                    background:
                      "linear-gradient(135deg, rgb(168, 0, 28), rgb(200, 50, 50))", // Gradiente sutil
                    display: "flex",
                    alignItems: "center", // Centraliza verticalmente o conteúdo
                    justifyContent: "center", // Centraliza horizontalmente o conteúdo
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <h2
                      style={{
                        fontSize: "1.2rem",
                        // margin: 0,
                        // textAlign: "center",
                        flex: 1, // Expande o h2 para ocupar espaço disponível
                      }}
                    >
                      Produtos
                    </h2>
                    <Checkbox
                      style={{
                        marginLeft: "auto",
                        color: "#fff",
                        "&.Mui-checked": {
                          color: "#fff",
                        },
                      }}
                      checked={isViweProdutos}
                      onChange={() => setIsViweProdutos(!isViweProdutos)}
                    />
                  </div>
                </div>

                {LoadProduto && isViweProdutos ? (
                  <CarregaProdutos
                    Codigo={Pedido}
                    Servico={Servico ? taxaServico : 0}
                    Produto={null}
                  />
                ) : null}
                {PedidoJaFaturado == false ? (
                  <>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(168, 0, 28)",
                        color: "white",
                        marginBottom: "10px",
                        borderRadius: "8px", // Canto arredondado
                        padding: "5px 0", // Espaçamento interno
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Sombra
                        background:
                          "linear-gradient(135deg, rgb(168, 0, 28), rgb(200, 50, 50))", // Gradiente sutil
                      }}
                    >
                      <h2 style={{ textAlign: "center", fontSize: "1.2rem" }}>
                        Tipo Pagamento
                      </h2>
                    </div>
                    <Pagamentos />
                  </>
                ) : null}
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(168, 0, 28)",
                    color: "white",
                    marginBottom: "10px",
                    borderRadius: "8px", // Canto arredondado
                    padding: "5px 0", // Espaçamento interno
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Sombra
                    background:
                      "linear-gradient(135deg, rgb(168, 0, 28), rgb(200, 50, 50))", // Gradiente sutil
                  }}
                >
                  <h2 style={{ textAlign: "center", fontSize: "1.2rem" }}>
                    Total
                  </h2>
                </div>
              </div>
            ) : (
              <>
                {/* <div style={{ height: '45vh' }}>


                            </div> */}
              </>
            )}

            {taxaServico > 0 ? (
              <>
                <div style={{ flex: "1 0 100%", marginBottom: "10px" }}>
                  <Toggle
                    Titulo={`Serviço (${taxaServico}%) ${MascaraMonetaria(
                      CalculaTaxaServico(TotalPedido) - TotalPedido
                    )}`}
                    Change={() => setServico(!Servico)}
                    Checked={Servico}
                  />
                </div>
              </>
            ) : null}

            <div style={{ flex: "1 0 100%", marginBottom: "10px" }}>
              <InputPedido
                Descricao="Total Pedido"
                Retorno={null}
                Valor={MascaraMonetaria(CalculaTaxaServico(TotalPedido))}
              />
            </div>
            <div style={{ flex: "1 0 100%", marginBottom: "10px" }}>
              <InputPedido
                Descricao="Total Pago"
                Retorno={null}
                Valor={MascaraMonetaria(TotalPago)}
              />
            </div>
            <div style={{ flex: "1 0 100%", marginBottom: "10px" }}>
              <InputPedido
                Descricao="Restante"
                Retorno={null}
                Valor={MascaraMonetaria(TotalDiferenca)}
              />
            </div>

            {NumeroCaixa > 0 && PedidoJaFaturado == false ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",

                    marginBottom: "10px",
                    height: "45px",
                    width: "100%",
                  }}
                >
                  {TotalDiferenca == 0 ? (
                    <>
                      <Button
                        descricao="Finalizar"
                        evento={FinalizaPagamento}
                        color="#138138"
                      />
                    </>
                  ) : null}
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(168, 0, 28)",
                    color: "white",
                    marginBottom: "10px",
                    borderRadius: "8px", // Canto arredondado
                    padding: "5px 0", // Espaçamento interno
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Sombra
                    background:
                      "linear-gradient(135deg, rgb(168, 0, 28), rgb(200, 50, 50))", // Gradiente sutil
                  }}
                >
                  <h2 style={{ textAlign: "center", fontSize: "1.2rem" }}>
                    Deve-se Abrir o Caixa!
                  </h2>
                </div>
              </>
            )}
          </div>

          {isMobileDevice() ? (
            <></>
          ) : (
            <div
              style={{
                width: isMobileDevice() ? "100%" : "80%",
                height: "90vh",
                overflow: "auto",
                marginLeft: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: "rgb(168, 0, 28)",
                  color: "white",
                  marginBottom: "10px",
                  borderRadius: "8px", // Canto arredondado
                  padding: "5px", // Espaçamento interno
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Sombra
                  background:
                    "linear-gradient(135deg, rgb(168, 0, 28), rgb(200, 50, 50))", // Gradiente sutil
                  display: "flex",
                  alignItems: "center", // Centraliza verticalmente o conteúdo
                  justifyContent: "center", // Centraliza horizontalmente o conteúdo
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h2
                    style={{
                      fontSize: "1.2rem",
                      // margin: 0,
                      // textAlign: "center",
                      flex: 1, // Expande o h2 para ocupar espaço disponível
                    }}
                  >
                    Produtos
                  </h2>
                  <Checkbox
                    style={{
                      marginLeft: "auto",
                      color: "#fff",
                      "&.Mui-checked": {
                        color: "#fff",
                      },
                    }}
                    checked={isViweProdutos}
                    onChange={() => setIsViweProdutos(!isViweProdutos)}
                  />
                </div>
              </div>

              {LoadProduto & isViweProdutos ? (
                <div style={{ padding: "10px" }}>
                  <CarregaProdutos
                    Codigo={Pedido}
                    Servico={Servico ? taxaServico : 0}
                    Produto={null}
                  />
                </div>
              ) : null}

              {PedidoJaFaturado == false ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "rgb(168, 0, 28)",
                      color: "white",
                      marginTop: "10px",
                      marginBottom: "10px",
                      borderRadius: "8px", // Canto arredondado
                      padding: "5px", // Espaçamento interno
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Sombra
                      background:
                        "linear-gradient(135deg, rgb(168, 0, 28), rgb(200, 50, 50))", // Gradiente sutil
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <h2
                        style={{
                          fontSize: "1.2rem",
                          // margin: 0,
                          // textAlign: "center",
                          flex: 1, // Expande o h2 para ocupar espaço disponível
                        }}
                      >
                        Pagamentos
                      </h2>
                      <Checkbox
                        style={{
                          display: "hidden",
                          marginLeft: "auto",
                          color: "transparent",
                          "&.Mui-checked": {
                            color: "transparent",
                          },
                        }}
                      />
                    </div>
                  </div>
                  <Pagamentos />
                </>
              ) : null}
            </div>
          )}
        </div>

        {isPix ? (
          <ComponentePIX
            base64={Base64}
            linha={LinhaDigitavel}
            total={TotalInformao}
            url={ConsultaPIX}
            PixRecebido={PixRecebido}
            Cancelar={() => setIsPix(false)}
          />
        ) : null}
      </>
    );
  };

  function AlteraValorTotal(event) {
    var input = document.querySelector(`input[name=total_informado]`);
    if (input) {
      console.log("CU2");
      ValorImputPago = MascaraMonetaria(RemoveMascara(event));
      input.value = ValorImputPago;
    }
  }

  return (
    <>
      {loading && <LoadingOverlay />}{" "}
      {ocultarTopo ? (
        <>
          <CardFechamento
            isViweProdutos={isViweProdutos}
            setIsViweProdutos={setIsViweProdutos}
          />
        </>
      ) : (
        <>
          <HeaderComponent InativarAtualizacoes={true}>
            <CardFechamento
              isViweProdutos={isViweProdutos}
              setIsViweProdutos={setIsViweProdutos}
            />
          </HeaderComponent>
        </>
      )}
    </>
  );
}
