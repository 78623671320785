// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket {
  width: 390px;
  height: 90px;
  background-color: #cccccc;
  position: relative;
  border-radius: 5px;
  border: 1px solid #cccccc;
}

.ticket-desconto {
  position: absolute;
  left: -48px;
  top: 50%;
  width: 90px;
  height: 60px;
  transform: translateY(-50%) rotate(-90deg);
  background-color: #51ad5a;
  padding: 5px;
  color: white;
  border-radius: 5px 5px 0 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.ticket-desconto::before,
.ticket::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 50%;
}

.ticket-desconto::before {
  position: absolute;
  top: -20px; /* Posicionando no topo */
  left: 50%; /* Centralizando horizontalmente */
  transform: translateX(-50%) rotate(-90deg); /* Rotacionando e centralizando horizontalmente */
  padding: 5px;
  color: white;
}

.ticket::after {
  top: 50%;
  right: -15px;
  width: 30px;
  height: 30px;
  transform: translateY(-50%);
}
`, "",{"version":3,"sources":["webpack://./src/page/cupom/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,0CAA0C;EAC1C,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,0BAA0B;EAC1B,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;;EAEE,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU,EAAE,yBAAyB;EACrC,SAAS,EAAE,kCAAkC;EAC7C,0CAA0C,EAAE,iDAAiD;EAC7F,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,QAAQ;EACR,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,2BAA2B;AAC7B","sourcesContent":[".ticket {\n  width: 390px;\n  height: 90px;\n  background-color: #cccccc;\n  position: relative;\n  border-radius: 5px;\n  border: 1px solid #cccccc;\n}\n\n.ticket-desconto {\n  position: absolute;\n  left: -48px;\n  top: 50%;\n  width: 90px;\n  height: 60px;\n  transform: translateY(-50%) rotate(-90deg);\n  background-color: #51ad5a;\n  padding: 5px;\n  color: white;\n  border-radius: 5px 5px 0 0;\n  text-align: center;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n}\n\n.ticket-desconto::before,\n.ticket::after {\n  content: \"\";\n  position: absolute;\n  width: 30px;\n  height: 30px;\n  background-color: #ffffff;\n  border-radius: 50%;\n}\n\n.ticket-desconto::before {\n  position: absolute;\n  top: -20px; /* Posicionando no topo */\n  left: 50%; /* Centralizando horizontalmente */\n  transform: translateX(-50%) rotate(-90deg); /* Rotacionando e centralizando horizontalmente */\n  padding: 5px;\n  color: white;\n}\n\n.ticket::after {\n  top: 50%;\n  right: -15px;\n  width: 30px;\n  height: 30px;\n  transform: translateY(-50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
