// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DivPrincipal {
  background-color: #ffffff;
  font-size: 16px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.DivMenu {
  display: flex;
  margin: 0 auto;
  width: 15%;
  gap: 10px;
  justify-content: center;
}

.DivMenu:hover {
  cursor: pointer;
}

.ProdutoSelected {
  color: #a8001c;

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-bottom: 2px solid #a8001c;
}

.ProdutoUnSelected {
  color: Black;

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.InsumoSelected {
  color: #a8001c;

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-bottom: 2px solid #a8001c;
}

.InsumoUnSelected {
  color: Black;

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Barra {
  width: 80%;
  height: 8px;
  background: #d9d9d9;
  margin: 10px auto;
}
`, "",{"version":3,"sources":["webpack://./src/page/estoque/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,eAAe;EACf,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,cAAc;EACd,UAAU;EACV,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;;EAEd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;EACE,YAAY;;EAEZ,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;;EAEd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;EACE,YAAY;;EAEZ,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".DivPrincipal {\n  background-color: #ffffff;\n  font-size: 16px;\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n}\n\n.DivMenu {\n  display: flex;\n  margin: 0 auto;\n  width: 15%;\n  gap: 10px;\n  justify-content: center;\n}\n\n.DivMenu:hover {\n  cursor: pointer;\n}\n\n.ProdutoSelected {\n  color: #a8001c;\n\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  border-bottom: 2px solid #a8001c;\n}\n\n.ProdutoUnSelected {\n  color: Black;\n\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n}\n\n.InsumoSelected {\n  color: #a8001c;\n\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  border-bottom: 2px solid #a8001c;\n}\n\n.InsumoUnSelected {\n  color: Black;\n\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n}\n\n.Barra {\n  width: 80%;\n  height: 8px;\n  background: #d9d9d9;\n  margin: 10px auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
