// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-checkbox {
  display: none;
}

.toggle-label {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  cursor: pointer;
}

.toggle-label .toggle-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;
}

.toggle-label .toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.toggle-checkbox:checked + .toggle-label .toggle-slider {
  background-color: black;
}

.toggle-checkbox:checked + .toggle-label .toggle-slider:before {
  transform: translateX(30px);
}

.toggle-label .toggle-text {
  vertical-align: middle;
  line-height: 30px; /* Isso deve coincidir com a altura do toggle */
}

.toggle-container {
  display: flex; /* Torna o contêiner flexível para alinhar seus filhos facilmente */
  align-items: center; /* Alinha os filhos no centro verticalmente */
  margin-left: 10px; /* Adiciona uma margem ao redor do contêiner. Ajuste o valor conforme necessário */
}
`, "",{"version":3,"sources":["webpack://./src/component/toggle/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,WAAW;EACX,SAAS;EACT,WAAW;EACX,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,sBAAsB;EACtB,iBAAiB,EAAE,+CAA+C;AACpE;;AAEA;EACE,aAAa,EAAE,mEAAmE;EAClF,mBAAmB,EAAE,6CAA6C;EAClE,iBAAiB,EAAE,kFAAkF;AACvG","sourcesContent":[".toggle-checkbox {\n  display: none;\n}\n\n.toggle-label {\n  position: relative;\n  display: inline-block;\n  width: 60px;\n  height: 30px;\n  cursor: pointer;\n}\n\n.toggle-label .toggle-slider {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: #ccc;\n  border-radius: 34px;\n  transition: 0.4s;\n}\n\n.toggle-label .toggle-slider:before {\n  position: absolute;\n  content: \"\";\n  height: 26px;\n  width: 26px;\n  left: 2px;\n  bottom: 2px;\n  background-color: white;\n  border-radius: 50%;\n  transition: 0.4s;\n}\n\n.toggle-checkbox:checked + .toggle-label .toggle-slider {\n  background-color: black;\n}\n\n.toggle-checkbox:checked + .toggle-label .toggle-slider:before {\n  transform: translateX(30px);\n}\n\n.toggle-label .toggle-text {\n  vertical-align: middle;\n  line-height: 30px; /* Isso deve coincidir com a altura do toggle */\n}\n\n.toggle-container {\n  display: flex; /* Torna o contêiner flexível para alinhar seus filhos facilmente */\n  align-items: center; /* Alinha os filhos no centro verticalmente */\n  margin-left: 10px; /* Adiciona uma margem ao redor do contêiner. Ajuste o valor conforme necessário */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
