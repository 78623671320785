// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-generico {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  gap: "20px";
}

.form-field-generico {
  /* display: flex; */
  /* flex-direction: column; */
  width: 100%;
  max-width: 200px;
  /* ou qualquer valor que você achar adequado */
  /* margin-bottom: 20px; */
  margin: "10px";
}

/* Estilos para telas menores */
@media (max-width: 768px) {
  .form-field-generico {
    max-width: 100%;
  }
}

body {
  font-family: "Roboto", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/component/generico/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,4BAA4B;EAC5B,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,4BAA4B;EAC5B,WAAW;EACX,gBAAgB;EAChB,8CAA8C;EAC9C,yBAAyB;EACzB,cAAc;AAChB;;AAEA,+BAA+B;AAC/B;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":[".container-generico {\n  display: flex;\n  /* flex-direction: column; */\n  align-items: center;\n  gap: \"20px\";\n}\n\n.form-field-generico {\n  /* display: flex; */\n  /* flex-direction: column; */\n  width: 100%;\n  max-width: 200px;\n  /* ou qualquer valor que você achar adequado */\n  /* margin-bottom: 20px; */\n  margin: \"10px\";\n}\n\n/* Estilos para telas menores */\n@media (max-width: 768px) {\n  .form-field-generico {\n    max-width: 100%;\n  }\n}\n\nbody {\n  font-family: \"Roboto\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
