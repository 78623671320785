import { useEffect, useState } from "react";
import { API } from "../../controller/api/api";
import { ApagaLocal, BuscaLocal, GravaLocal } from "../../controller/storage";
import {
  GetCache,
  Notifica,
  NotificaWarning,
  PrimeiraMaiuscula,
  Upper,
} from "../../controller/util";
import {
  DataAtual,
  MascaraMonetaria,
  RemoveMascara,
  calcularTempoDecorrido,
  formatarData,
  formatarHora,
  getCodigoUsuario,
  getUsuario,
  obterHoraAtual,
  transformarData,
} from "../../controller/data";
import { InputPedido } from "../../page/pedido/componente/input";
import { CabecalhoPediodo } from "../cabecalhopedido";
import LoadingOverlay from "../load";
import { ListaProduto } from "../../page/salaopage/componente/listaproduto";
// import Select from "react-select";
import { Button } from "../button";
import { Fa500Px, FaHotTub } from "react-icons/fa";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
} from "@mui/material";
import { isMobileDevice } from "../../controller/mobile";

let GravarDadosCliente = false;

export function ComponentePedido({ id }) {
  const [Data, setData] = useState("");
  const [Hora, setHora] = useState("");
  const [Tempo, setTempo] = useState("");
  const [Tipo, setTipo] = useState(
    DadosClienteCache("type") ? DadosClienteCache("type") : 0
  );
  const [Pagamento, setPagamento] = useState(1);
  const [Produtos, setProdutos] = useState(MascaraMonetaria(0));
  const [Acrecimo, setAcrecimo] = useState(MascaraMonetaria(0));
  const [Entrega, setEntrega] = useState(DadosClienteCache("taxa"));

  const [Desconto, setDesconto] = useState(MascaraMonetaria(0));
  const [Total, setTotal] = useState(MascaraMonetaria(0));
  const [Troco, setTroco] = useState(MascaraMonetaria(0));
  const [ProdutoPedido, setProdutoPedido] = useState([]);
  const [DadosPrincipalPedido, setDadosPrincipalPedido] = useState(null);
  const [TipoPagamento, setTipoPagamento] = useState([]);
  const [Rua, setRua] = useState(DadosClienteCache("rua"));
  const [Bairro, setBairo] = useState(DadosClienteCache("bairro"));
  const [Cidade, setCidade] = useState(DadosClienteCache("cidade"));
  const [Estado, setEstado] = useState(DadosClienteCache("estado"));
  const [Complemento, setComplemento] = useState(
    DadosClienteCache("complemento")
  );
  const [Numero, setNumero] = useState(DadosClienteCache("numero"));
  const [NovoPedido, setNovoPedido] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Endereco, setEndereco] = useState(
    DadosClienteCache("endereco") ? DadosClienteCache("endereco") : 0
  );

  const [DadosCliente, setDadosCliente] = useState([]);
  const [DadosClienteEndereco, setDadosClienteEndereco] = useState([]);

  const [NomeBalcao, setNomeBalcao] = useState("");
  const [NomeCliente, setNomeCliente] = useState(DadosClienteCache("nome"));
  const [CelularCliente, setCelularCliente] = useState(
    DadosClienteCache("celular")
  );
  const [CPFCliente, setCPFCliente] = useState(DadosClienteCache("cpf"));
  const [Option, setOption] = useState([]);

  const [PedidoiFood, setPedidoiFood] = useState(false);

  function BuscaDadosPrincipal() {
    API.get("v1/dados/pedido/" + id + "/" + getCodigoUsuario()).then(function (
      dados
    ) {
      if (id == 0) {
        window.location.href = "/pedido/" + dados.data?.codigo;
      } else {
        setDadosPrincipalPedido(dados.data);
      }
    });
  }

  useEffect(() => {
    if (TipoPagamento.length > 0) {
      setPagamento(TipoPagamento[0]?.codigo);
    }
  }, [TipoPagamento]);

  useEffect(() => {
    //console.log(Endereco);
  }, [Endereco]);

  useEffect(() => {
    if (id > 0) {
      GetCache("v1/tipo/pagamento/", 5).then(function (dados) {
        setTipoPagamento(dados);
      });

      GetCache("/v1/consulta/generica/taxa_entrega/*/*/*").then(function (
        dados
      ) {
        let result = [];

        for (var i = 0; i < dados.length; i++) {
          result.push({
            value: i,
            taxa: dados[i].valorTaxa,
            bairro: dados[i].bairro,
            cidade: dados[i].cidade,
            estado: dados[i].estado,
            label: `${PrimeiraMaiuscula(dados[i].bairro)} (${PrimeiraMaiuscula(
              dados[i].cidade
            )}/${dados[i].estado}) ${MascaraMonetaria(dados[i].valorTaxa)}`,
          });
        }

        setOption(result);

        // setBairo(dados.data[0].bairro);
        // setCidade(dados.data[0].cidade);
        // setEstado(dados.data[0].estado);
        // setEntrega(dados.data[0].valorTaxa);
      });
    }

    BuscaDadosPrincipal();
  }, []);

  useEffect(() => {
    if (Tipo == 1) {
      let TotalEntrega = 0;
      ProdutoPedido.forEach((item, index) => {
        TotalEntrega += item.entrega * item.quantidade;
      });

      setAcrecimo(MascaraMonetaria(TotalEntrega));
    } else {
      setAcrecimo(0);
    }
  }, [Tipo]);

  useEffect(() => {
    BuscaDados();
  }, [DadosPrincipalPedido]);

  function BuscaDados() {
    if (DadosPrincipalPedido != undefined) {
      setData(formatarData(DadosPrincipalPedido.dataPedido));
      setHora(formatarHora(DadosPrincipalPedido.horaPedido));
      setNomeBalcao(PrimeiraMaiuscula(DadosPrincipalPedido.nome));
      setTempo(
        calcularTempoDecorrido(
          DadosPrincipalPedido.dataPedido +
            " " +
            DadosPrincipalPedido.horaPedido
        )
      );
      setAcrecimo(MascaraMonetaria(0));
      setEntrega(DadosPrincipalPedido.valorTaxaEntrega);

      setDesconto(MascaraMonetaria(DadosPrincipalPedido.valorDesconto));

      setPagamento(
        DadosPrincipalPedido.tipoPagamento > 0
          ? DadosPrincipalPedido.tipoPagamento
          : TipoPagamento[0]?.codigo
      );

      if (DadosPrincipalPedido.codigoCliente > 0) {
        setTipo(DadosPrincipalPedido.codigoClienteEndereco > 0 ? 1 : 0);
        API.get(
          "v1/dados/consulta/cliente/" + DadosPrincipalPedido.codigoCliente
        ).then(function (dados) {
          if (dados?.data[0]?.nome != undefined) {
            setNomeCliente(PrimeiraMaiuscula(dados?.data[0]?.nome));
          }

          if (dados?.data[0]?.celular != undefined) {
            setCelularCliente(PrimeiraMaiuscula(dados?.data[0]?.celular));
          }

          if (dados?.data[0]?.cpf != undefined) {
            setCPFCliente(dados?.data[0]?.cpf);
          }

          setDadosCliente(dados.data[0]);
        });
        API.get(
          "v1/dados/consulta/cliente/endereco/" +
            DadosPrincipalPedido.codigoClienteEndereco
        ).then(function (dados) {
          // for (let i = 0; i < Option.length; i++) {
          //   if (
          //     PrimeiraMaiuscula(Option[i].bairro) ==
          //     PrimeiraMaiuscula(dados?.data[0]?.bairro)
          //   ) {
          //     break;
          //   }
          // }

          for (let i = 0; i < Option.length; i++) {
            //console.log(Option[i].bairro + "-" + dados?.data[0]?.bairro);
            if (
              PrimeiraMaiuscula(Option[i].bairro) ==
              PrimeiraMaiuscula(dados?.data[0]?.bairro)
            ) {
              setEndereco(Option[i].label);
              // setEntrega(Option[i].taxa);

              break;
            }
          }

          setRua(PrimeiraMaiuscula(dados?.data[0]?.rua));
          setBairo(dados?.data[0]?.bairro);
          //console.log(dados?.data[0]?.bairro);
          setCidade(dados?.data[0]?.cidade);
          setEstado(dados?.data[0]?.estado);
          setNumero(PrimeiraMaiuscula(dados?.data[0]?.numero));
          setComplemento(PrimeiraMaiuscula(dados?.data[0]?.complemento));
          setDadosClienteEndereco(dados?.data[0]);
        });
      } else {
        //Allan
        setRua(PrimeiraMaiuscula(DadosClienteCache("rua")));
        setBairo(DadosClienteCache("bairro"));
        setCidade(DadosClienteCache("cidade"));
        setEstado(DadosClienteCache("estado"));
        setNumero(PrimeiraMaiuscula(DadosClienteCache("numero")));
        setComplemento(PrimeiraMaiuscula(DadosClienteCache("complemento")));
        setEndereco(DadosClienteCache("endereco"));
        setEntrega(RemoveMascara(DadosClienteCache("taxa")));

        // Aki pegar o endereço allan
        //   endereco: Endereco,
        // taxa: TaxaEntrega,
      }

      setAcrecimo(MascaraMonetaria(DadosPrincipalPedido.taxaServico));

      API.get("v1/pedido/produtos/" + id).then(function (dados) {
        let TotalProduto = 0;
        let TotalEntrega = 0;
        dados.data.forEach((item, index) => {
          TotalProduto += item.valorTotal;
          TotalEntrega += item.entrega * item.quantidade;
        });

        setProdutos(MascaraMonetaria(TotalProduto));
        if (Tipo == 1) {
          setAcrecimo(MascaraMonetaria(TotalEntrega));
        }
        //console.log(dados.data);
        setProdutoPedido(dados.data);
      });
      GravaLocal(
        "VOLTAR",
        `/pedido/${
          DadosPrincipalPedido != undefined ? DadosPrincipalPedido.codigo : 0
        }`
      );
    }
  }

  useEffect(RecalculaTotal, [Produtos]);
  useEffect(RecalculaTotal, [Entrega]);
  useEffect(RecalculaTotal, [Tipo]);
  useEffect(RecalculaTotal, [Desconto]);
  useEffect(RecalculaTotal, [Acrecimo]);
  useEffect(ConsultaCliente, [CelularCliente]);
  useEffect(RecalculaTotal, [Rua]);
  useEffect(RecalculaTotal, [Bairro]);
  useEffect(RecalculaTotal, [Cidade]);
  useEffect(RecalculaTotal, [Estado]);
  useEffect(RecalculaTotal, [Complemento]);
  useEffect(RecalculaTotal, [Numero]);
  useEffect(() => {
    // Chame a função GravarDadosCache
    GravarDadosCache();
  }, [
    Endereco,
    GravarDadosCliente,
    Tipo,
    CelularCliente,
    NomeCliente,
    CPFCliente,
    Rua,
    Bairro,
    Cidade,
    Numero,
    Complemento,
    Entrega,
  ]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#f5f5f5",
      minHeight: "34px",
      height: "54px",
      borderColor: "black",
      transition: "border-color 0.1s ease 0s",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#A8001C"
        : state.isFocused
        ? "#e5e5e5"
        : null,
      color: state.isSelected ? "white" : null,
    }),
  };

  function ConsultaCliente() {
    //console.log("ConsultaCliente");
    if (extrairNumeros(CelularCliente)?.length > 10) {
      API.get(
        `v1/dados/consulta/cliente/celular/${extrairNumeros(CelularCliente)}`
      ).then(function (response) {
        //console.log(response.data);
        let dados = response.data[0];

        if (dados != undefined) {
          setRua(PrimeiraMaiuscula(dados.rua));

          for (let i = 0; i < Option.length; i++) {
            //console.log(Option[i].bairro + "-" + dados.bairro);
            if (
              PrimeiraMaiuscula(Option[i].bairro) ==
              PrimeiraMaiuscula(dados.bairro)
            ) {
              setEndereco(Option[i].label);
              setEntrega(Option[i].taxa);

              break;
            }
          }

          //console.log(dados);
          setBairo(dados.bairro);
          setCidade(dados.cidade);
          setEstado(dados.estado);
          setComplemento(dados.complemento);
          setNumero(dados.numero);
          // setEntrega(MascaraMonetaria(dados.valorTaxa));

          setCelularCliente(dados.celular);
          setCPFCliente(dados.cpf);
          setNomeCliente(PrimeiraMaiuscula(dados?.nome));
          setDadosCliente(dados);

          GravarDadosCliente = true;
          GravarDadosCache();
        } else {
        }
      });
    }
  }

  function RecalculaTotal() {
    let total = 0;

    total =
      RemoveMascara(Produtos) +
      RemoveMascara(Acrecimo) +
      RemoveMascara(Entrega) -
      RemoveMascara(Desconto);
    if (Tipo != 1) {
      // ConsultaCliente();
      total = total - RemoveMascara(Entrega);
    }

    setTotal(MascaraMonetaria(total));
  }

  function GravarDadosCache() {
    if (DadosPrincipalPedido == undefined) {
      return false;
    }

    if (DadosPrincipalPedido?.codigoPedidoDia > 0) {
      return false;
    }

    //Allan
    if (!GravarDadosCliente) {
      return true;
    }
    //Allan
    let body = {
      type: Tipo,
      celular: extrairNumeros(CelularCliente),
      nome: NomeCliente,
      cpf: CPFCliente,
      rua: Rua,
      bairro: Bairro,
      cidade: Cidade,
      numero: Numero,
      complemento: Complemento,
      endereco: Endereco,
      taxa: Entrega,
    };
    localStorage.setItem("DADOS_CLIENTE", JSON.stringify(body));
  }

  function DadosClienteCache(campo) {
    let data = JSON.parse(BuscaLocal("DADOS_CLIENTE"));
    if (data) {
      return data[campo];
    } else {
      if (campo == "taxa") {
        return 0;
      }
      return null;
    }
  }

  function extrairNumeros(telefone) {
    return telefone?.replace(/\D/g, "");
  }

  function ValidacoesPedido() {
    if (RemoveMascara(Total) == "0") {
      NotificaWarning("Sem produtos!");
      return false;
    }

    if (CelularCliente === null) {
      NotificaWarning("Celular não informado!" + CelularCliente);
      return false;
    }

    if (extrairNumeros(CelularCliente)?.length < 11) {
      NotificaWarning("Celular não informado!" + CelularCliente);
      return false;
    }

    if (NomeCliente === "") {
      NotificaWarning("Nome não informado!");
      return false;
    }

    if (Tipo == 1) {
      if (Rua === "") {
        NotificaWarning("Rua não informada!");
        return false;
      }
      if (Numero === "") {
        NotificaWarning("Número não informada!");
        return false;
      }
    }
    return true;
  }

  function SalvarDados(newOrder) {
    if (!ValidacoesPedido()) {
      return false;
    }

    setLoading(true);

    let data = [];

    if (DadosPrincipalPedido.codigoPedidoDia == 0) {
      setNovoPedido(true);
    }
    let dataPedido = transformarData(Data);
    let horaPedido = Hora;
    // if (DadosPrincipalPedido.codigoPedidoDia == 0) {
    //   dataPedido = DataAtual();
    //   horaPedido = obterHoraAtual();
    // }

    const index = Option.findIndex((item) => item.label === Endereco);

    const bairro = Option[index]?.bairro ? Option[index]?.bairro : "";
    const cidade = Option[index]?.cidade ? Option[index]?.cidade : "";
    const estado = Option[index]?.estado ? Option[index]?.estado : "";

    data.push({
      codigo: id,
      status: DadosPrincipalPedido.status < 0 ? 2 : DadosPrincipalPedido.status,
      data: dataPedido,
      hora: horaPedido,
      produto: RemoveMascara(Produtos),
      acrecimo: RemoveMascara(Acrecimo),
      entrega: Tipo == 1 ? RemoveMascara(Entrega) : 0,
      desconto: RemoveMascara(Desconto),
      total: RemoveMascara(Total),
      troco:
        RemoveMascara(Troco) > 0
          ? RemoveMascara(Total) - RemoveMascara(Troco)
          : 0,
      cliente:
        DadosCliente == undefined
          ? 0
          : DadosCliente?.codigo
          ? DadosCliente?.codigo
          : 0,
      celular: CelularCliente,
      cpf: CPFCliente,
      nome: Upper(NomeCliente),
      balcao: Upper(NomeBalcao),
      rua: Upper(Rua),
      bairro: Upper(bairro),
      cidade: Upper(cidade),
      estado: Upper(estado),
      complemento: Upper(Complemento),
      numero: Upper(Numero),
      endereco: Tipo == 1 ? DadosPrincipalPedido.codigoClienteEndereco : -1,
      tipoPagamento:
        Pagamento == 0 ? TipoPagamento[0]?.codigo : parseInt(Pagamento),
    });

    API.post("v1/atualiza/dados/pedido/", data).then(function (dados) {
      BuscaDadosPrincipal();
      Notifica("Pedido salvo!");

      if (DadosPrincipalPedido.codigoPedidoDia == 0) {
        API.post("/v1/imprimir/1/" + id + "/0");
        API.get("/v1/produtos/pedido/2/" + id).then(function (result) {
          API.post("v1/imprimir", result.data);
          setLoading(false);
        });
      }
      setLoading(false);
      GravaLocal("VOLTAR_PEDIDO", "/pedidos");
      let Voltar = BuscaLocal("VOLTAR_PEDIDO");
      ApagaLocal("VOLTAR_PEDIDO");
      if (Voltar != undefined) {
        window.location.href = Voltar;
      }

      // API.get("/v1/produtos/pedido/1/" + id).then(function (result) {
      //   API.post("v1/imprimir", result.data).then(function (result) {
      //     if (newOrder) {
      //       GravaLocal("VOLTAR_PEDIDO", "/pedidos");
      //       let Voltar = BuscaLocal("VOLTAR_PEDIDO");
      //       ApagaLocal("VOLTAR_PEDIDO");
      //       if (Voltar != undefined) {
      //         window.location.href = Voltar;
      //       }
      //     }
      //   });
      // });
    });
  }

  function AtualizarDadosSemSair() {
    SalvarDados(false);
  }

  function TipoPagamentoTroco(codigo) {
    // Verifica se o array TipoPagamento está definido e não é vazio
    if (Array.isArray(TipoPagamento) && TipoPagamento.length > 0) {
      // Itera sobre cada objeto no array TipoPagamento
      for (let i = 0; i < TipoPagamento.length; i++) {
        // Verifica se o código do objeto atual corresponde ao código fornecido
        if (TipoPagamento[i].codigo === codigo) {
          // Se corresponder, retorna o objeto atual
          return TipoPagamento[i].trocoDelivery == 1;
        }
      }
    }
    // Se o código não for encontrado, retorna null ou uma mensagem indicando que não foi encontrado
    return false; // ou return "Código não encontrado";
  }

  function AtualizaDados() {
    SalvarDados(true);
    ApagaLocal("DADOS_CLIENTE");
  }

  function AlteraValoresInput(type, valor) {
    if (type == "Acrécimo") {
      valor = RemoveMascara(valor);
      setAcrecimo(MascaraMonetaria(valor));
    }
    if (type == "Desconto") {
      valor = RemoveMascara(valor);
      setDesconto(MascaraMonetaria(valor));
    }
    if (type == "Celular") {
      valor = valor;
      setCelularCliente(valor);
    }
    if (type == "CPF") {
      valor = valor;
      setCPFCliente(valor);
    }
    if (type == "Nome") {
      valor = PrimeiraMaiuscula(valor);
      setNomeCliente(valor);
    }
    if (type == "Identificação do Cliente Balcão*") {
      valor = PrimeiraMaiuscula(valor);
      setNomeBalcao(valor);
    }

    if (type == "Número") {
      valor = PrimeiraMaiuscula(valor);
      setNumero(valor);
    }
    if (type == "Complemento") {
      valor = PrimeiraMaiuscula(valor);
      setComplemento(valor);
    }
    if (type == "Rua") {
      valor = PrimeiraMaiuscula(valor);
      setRua(valor);
    }

    GravarDadosCache();

    //setRua
  }

  const handleAutocompleteChange = (event, newValue) => {
    if (newValue) {
      const index = Option.findIndex((item) => item.label === newValue);

      setEntrega(Option[index].taxa);
      setBairo(Option[index].bairro);
      setCidade(Option[index].cidade);
      setEstado(Option[index].estado);
      setEndereco(newValue);
    } else {
      setEntrega("");
      setBairo("");
      setCidade("");
      setEstado("");
      setEndereco("");
    }
  };

  function iFood() {
    if (!DadosPrincipalPedido) {
      return false;
    }
    if (!DadosPrincipalPedido.idIfood) {
      return false;
    }
    //console.log(DadosPrincipalPedido.idIfood);
    if (DadosPrincipalPedido.idIfood == null) {
      return false;
    }
    return DadosPrincipalPedido.idIfood != "" ? true : false;
  }

  const ComponentesMobile = () => {
    return (
      <>
        <div style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
          <InputPedido
            Descricao="Data"
            Retorno={null}
            Valor={Data}
            disabled={iFood()}
          />
          <InputPedido
            Descricao="Hora"
            Retorno={null}
            Valor={Hora}
            disabled={iFood()}
          />
        </div>
        <div style={{ marginBottom: "15px", display: "flex", gap: "10px" }}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="pagamento">Forma De Pagamento</InputLabel>
            <Select
              id="pagamento"
              value={Pagamento}
              label="Forma De Pagamento"
              onChange={(e) => setPagamento(e.target.value)}
              size="small"
              disabled={iFood()}
            >
              {TipoPagamento.map((item, index) => (
                <MenuItem key={index} value={item?.codigo}>
                  {PrimeiraMaiuscula(item.descricao)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {TipoPagamentoTroco(Pagamento) && (
            <InputPedido
              Descricao="Troco"
              Set={setTroco}
              Valor={MascaraMonetaria(RemoveMascara(Troco))}
              sx={{ width: "150px" }}
              disabled={iFood()}
            />
          )}
        </div>
        <div style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
          <InputPedido
            Descricao="Produto"
            Retorno={null}
            Valor={Produtos}
            disabled={iFood()}
          />
          <InputPedido
            Descricao="Acrécimo"
            Retorno={AlteraValoresInput}
            Valor={MascaraMonetaria(RemoveMascara(Acrecimo))}
            disabled={iFood()}
          />
        </div>
        <div style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
          <InputPedido
            Descricao="Desconto"
            Retorno={AlteraValoresInput}
            Valor={Desconto}
            disabled={iFood()}
          />
          {Tipo == 1 ? (
            <>
              <InputPedido
                Descricao="Entrega"
                Retorno={AlteraValoresInput}
                Valor={MascaraMonetaria(Entrega)}
                disabled={iFood()}
              />
            </>
          ) : null}
        </div>

        <InputPedido
          Descricao="Total"
          Retorno={null}
          Valor={Total}
          disabled={iFood()}
          sx={{ marginBottom: "15px" }}
        />

        {!PedidoiFood ? (
          <div
            style={{
              display: "flex",
              marginBottom: "15px",
              gap: "15px",
              width: "100%",
              // flexDirection: "column",
            }}
          >
            <InputPedido
              Descricao="Celular"
              Set={setCelularCliente}
              // Change={true}
              id="celularCliente"
              Tipo="celular"
              Valor={CelularCliente}
              // sx={{ width: "100%" }}
              disabled={iFood()}
            />

            {iFood() ? null : (
              <>
                <IconButton
                  color="black"
                  sx={{ width: "40px" }}
                  onClick={() => (
                    setCelularCliente("00000000000"),
                    setNomeCliente(PrimeiraMaiuscula("Balcão"))
                  )}
                >
                  <FaHotTub />
                </IconButton>
              </>
            )}
          </div>
        ) : null}

        <InputPedido
          Descricao="Nome"
          Retorno={AlteraValoresInput}
          Mascara=""
          Valor={NomeCliente}
          sx={{ marginBottom: "15px" }}
          disabled={iFood()}
        />
        <InputPedido
          Descricao="CPF"
          Retorno={AlteraValoresInput}
          Tipo="cpf"
          Valor={CPFCliente}
          sx={{ marginBottom: "15px" }}
          disabled={iFood()}
        />
        {Tipo == 1 && !PedidoiFood ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              marginTop: "15px",
            }}
          >
            <InputPedido
              Descricao="Rua"
              Retorno={AlteraValoresInput}
              Valor={Rua}
              sx={{ width: "100%" }}
              disabled={iFood()}
            />

            <Autocomplete
              disabled={iFood()}
              value={Endereco}
              onChange={handleAutocompleteChange}
              options={Option.map((item) => item.label)}
              renderInput={(params) => (
                <TextField {...params} label="Bairro" variant="outlined" />
              )}
              fullWidth
              size="small"
            />

            <InputPedido
              Descricao="Número"
              Retorno={AlteraValoresInput}
              Valor={Numero}
              disabled={iFood()}
            />

            <InputPedido
              Descricao="Complemento"
              Retorno={AlteraValoresInput}
              Valor={Complemento}
              disabled={iFood()}
            />
          </div>
        ) : null}

        {NomeCliente == "Balcão" ? (
          <>
            <InputPedido
              Descricao="Identificação do Cliente Balcão*"
              Retorno={AlteraValoresInput}
              Mascara=""
              Valor={NomeBalcao}
              sx={{ marginTop: "15px" }}
              disabled={iFood()}
            />
          </>
        ) : null}
      </>
    );
  };

  const ComponentesDesktop = () => {
    return (
      <>
        <div style={{ display: "flex", gap: "10px" }}>
          <InputPedido
            Descricao="Data"
            Retorno={null}
            Valor={Data}
            sx={{ width: "20%" }}
            disabled={iFood()}
          />

          <InputPedido
            Descricao="Hora"
            Retorno={null}
            Valor={Hora}
            sx={{ width: "20%" }}
            disabled={iFood()}
          />

          <InputPedido
            Descricao="Tempo"
            Retorno={null}
            Valor={Tempo}
            sx={{ width: "20%" }}
            disabled={iFood()}
          />
          <FormControl sx={{ width: "90%" }}>
            <InputLabel disabled={iFood()} id="pagamento">
              Forma De Pagamento
            </InputLabel>
            <Select
              disabled={iFood()}
              id="pagamento"
              value={Pagamento}
              label="Forma De Pagamento"
              onChange={(e) => setPagamento(e.target.value)}
              size="small"
            >
              {TipoPagamento.map((item, index) => (
                <MenuItem key={index} value={item?.codigo}>
                  {PrimeiraMaiuscula(item.descricao)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {TipoPagamentoTroco(Pagamento) && (
            <InputPedido
              Descricao="Troco"
              Set={setTroco}
              Valor={MascaraMonetaria(RemoveMascara(Troco))}
              sx={{ width: "25%" }}
              disabled={iFood()}
            />
          )}
        </div>

        {/* <div style={{ flex: "1 0 100%", height: "10px" }}></div> */}
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            gap: "10px",
            // flexDirection: "column",
          }}
        >
          <InputPedido
            Descricao="Produto"
            Retorno={null}
            Valor={Produtos}
            disabled={iFood()}
            // sx={{ flex: "1 0 160px" }}
          />

          <InputPedido
            Descricao="Acrécimo"
            Retorno={AlteraValoresInput}
            Valor={MascaraMonetaria(RemoveMascara(Acrecimo))}
            disabled={iFood()}
            // sx={{ flex: "1 0 160px" }}
          />

          {Tipo == 1 ? (
            <>
              <InputPedido
                disabled={iFood()}
                Descricao="Entrega"
                Retorno={AlteraValoresInput}
                Valor={MascaraMonetaria(Entrega)}
                // sx={{ flex: "1 0 160px" }}
              />
            </>
          ) : null}

          <InputPedido
            disabled={iFood()}
            Descricao="Desconto"
            Retorno={AlteraValoresInput}
            Valor={Desconto}
          />

          <InputPedido
            Descricao="Total"
            Retorno={null}
            Valor={Total}
            disabled={iFood()}
          />
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "10px",
            gap: "10px",
            // flexDirection: "column",
          }}
        >
          {!PedidoiFood ? (
            <div style={{ flex: "1 0 160px" }}>
              <div style={{ display: "flex" }}>
                <InputPedido
                  disabled={iFood()}
                  Descricao="Celular"
                  // Retorno={AlteraValoresInput}
                  Set={setCelularCliente}
                  // Change={true}
                  Valor={CelularCliente}
                  id="celularCliente"
                  Tipo="celular"
                />

                {iFood() ? (
                  <></>
                ) : (
                  <>
                    <IconButton
                      color="black"
                      sx={{ marginLeft: "10px" }}
                      onClick={() => (
                        setCelularCliente("00000000000"),
                        setNomeCliente(PrimeiraMaiuscula("Balcão"))
                      )}
                    >
                      <FaHotTub />
                    </IconButton>
                  </>
                )}
              </div>
            </div>
          ) : null}

          <div style={{ flex: "1 0 340px" }}>
            <InputPedido
              disabled={iFood()}
              Descricao="Nome"
              Retorno={AlteraValoresInput}
              Mascara=""
              Valor={NomeCliente}
            />
          </div>

          <div style={{ flex: "1 0 340px" }}>
            <InputPedido
              disabled={iFood()}
              Descricao="CPF"
              Retorno={AlteraValoresInput}
              Tipo="cpf"
              Valor={CPFCliente}
            />
          </div>
        </div>

        {Tipo == 1 && !PedidoiFood ? (
          <>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                gap: "10px",
              }}
            >
              <InputPedido
                disabled={iFood()}
                Descricao="Rua"
                Retorno={AlteraValoresInput}
                Valor={Rua}
                sx={{ width: "100%" }}
              />

              <div style={{ flex: "1 0 305px" }}>
                <Autocomplete
                  disabled={iFood()}
                  value={Endereco}
                  onChange={handleAutocompleteChange}
                  options={Option.map((item) => item.label)}
                  renderInput={(params) => (
                    <TextField {...params} label="Bairro" variant="outlined" />
                  )}
                  fullWidth
                  size="small"
                />
              </div>

              <InputPedido
                disabled={iFood()}
                Descricao="Número"
                Retorno={AlteraValoresInput}
                Valor={Numero}
              />

              <InputPedido
                disabled={iFood()}
                Descricao="Complemento"
                Retorno={AlteraValoresInput}
                Valor={Complemento}
              />
            </div>
          </>
        ) : null}
        {NomeCliente == "Balcão" ? (
          <>
            <InputPedido
              Descricao="Identificação do Cliente Balcão*"
              Retorno={AlteraValoresInput}
              Mascara=""
              Valor={NomeBalcao}
              sx={{ marginTop: "15px" }}
              disabled={iFood()}
            />
          </>
        ) : null}
      </>
    );
  };

  function SomaNovoTotal(valor) {
    return RemoveMascara(Produtos) + valor - RemoveMascara(Desconto);
  }
  return (
    <>
      {loading && <LoadingOverlay />}
      <CabecalhoPediodo
        Faturado={
          DadosPrincipalPedido?.idCaixa === null ||
          DadosPrincipalPedido?.idCaixa === 0
            ? false
            : true
        }
        Descricao={`Pedido ${
          DadosPrincipalPedido != undefined
            ? "Nº " + DadosPrincipalPedido.codigoPedidoDia
            : ""
        }`}
        Pedido={
          DadosPrincipalPedido != undefined ? DadosPrincipalPedido.codigo : 0
        }
        id={0}
      />

      {DadosPrincipalPedido?.idCaixa === null ||
      DadosPrincipalPedido?.idCaixa === 0 ? (
        <></>
      ) : (
        <>
          <a href={"/resumo-caixa/" + DadosPrincipalPedido?.idCaixa}>
            <h3 style={{ color: "green" }}>
              Pedido Faturado{" "}
              <span
                style={{ fontWeight: "bold", color: "rgba(168, 0, 28,0.5)" }}
              >
                Caixa Nº{DadosPrincipalPedido?.idCaixa}
              </span>
            </h3>
          </a>
        </>
      )}

      <div style={{ height: "100%", width: "100%", marginTop: "10px" }}>
        <div style={{ display: "flex", height: "50px", marginBottom: "10px" }}>
          <div
            onClick={() => setTipo(0)}
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: `${Tipo == 0 ? "1px solid rgba(0,0,0,0.3)" : "none"}`,
              borderRadius: "5px",
            }}
          >
            <IconButton color="black">
              <img
                src="../../do-icone-balcao.png"
                alt="Ícone de balcão"
                style={{ height: "30px", width: "30px" }}
              />
            </IconButton>

            <span style={{ marginLeft: "5px" }}>Vem Buscar</span>
          </div>
          <div
            onClick={() => setTipo(1)}
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: `${Tipo == 1 ? "1px solid rgba(0,0,0,0.3)" : "none"}`,
              borderRadius: "5px",
            }}
          >
            <IconButton color="black">
              <img
                src="../../do-icone-localizacao.png"
                alt="Ícone de balcão"
                style={{ height: "30px", width: "30px" }}
              />
            </IconButton>

            <span style={{ marginLeft: "5px" }}>Delivery</span>
          </div>
        </div>

        {isMobileDevice() ? <ComponentesMobile /> : <ComponentesDesktop />}

        <div style={{ padding: "15px" }}>
          <ListaProduto
            dados={ProdutoPedido}
            atualizar={AtualizarDadosSemSair}
            Faturado={
              iFood()
                ? true
                : DadosPrincipalPedido?.idCaixa === null ||
                  DadosPrincipalPedido?.idCaixa === 0
                ? false
                : true
            }
          ></ListaProduto>
        </div>
        {DadosPrincipalPedido?.idCaixa === null ||
        DadosPrincipalPedido?.idCaixa === 0 ? (
          <>
            {PedidoiFood == false ? (
              <>
                <div
                  style={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#ffffff", // Cor de fundo opcional
                    padding: "10px", // Espaçamento interno opcional
                    borderTop: "1px solid #e0e0e0", // Borda superior opcional
                    // zIndex: 1000, // Z-index opcional
                  }}
                >
                  <Button
                    descricao="Salvar Pedido"
                    evento={AtualizaDados}
                    color="#138138"
                    borderColor="#138138"
                    fontColor="#138138"
                    BorderRadius="5px"
                    disabled={iFood()}
                  />
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
}
