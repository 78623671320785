import { useEffect, useState } from "react";
import { API } from "../../../../controller/api/api";
import { TextField } from "@mui/material";

export function InputParametro(props) {
  const [ValorInput, setValorInput] = useState(props.Valor);
  let tipo = "text";
  let transform = "translate(-10%, -10%)";
  // //////console.log(typeof props.Tipo);

  if (typeof props.Tipo != undefined) {
    tipo = props.Tipo;
    transform = "translate(-45%, -25%)";
  }

  useEffect(() => {
    setValorInput(props.Valor);
  }, [props.Valor]);

  const ChangeInput = (valor) => {
    if (typeof props.Retorno == "function") {
      setValorInput(valor);

      let Body = { campo: props.campo, valor: valor };

      API.post(`/v2/parametro`, Body);

      if (props.index != undefined) {
        props.Retorno(props.index, valor);
      } else {
        props.Retorno(props.Descricao, valor);
      }
    }
  };
  return (
    <>
      <TextField
        size="small"
        label={props.Descricao}
        variant="outlined"
        onBlur={(e) => {
          ChangeInput(ValorInput);
          e.target.style.backgroundColor = "white";
        }}
        type={tipo}
        onChange={(e) => setValorInput(e.target.value)}
        onClick={(e) => setValorInput(e.target.value)}
        // onFocus={(e) =>
        //   (e.target.style.backgroundColor = "rgba(168, 0, 28, 0.15)")
        // }
        value={ValorInput}
        fullWidth
        sx={{
          marginBottom: "10px",
          ...(props.sx || {}),
        }}
      />
      {/* <span>{props.Descricao}</span>
            <div style={{ position: 'relative', marginBottom: '10px' }}>
                <label
                    style={{
                        position: 'absolute',
                        top: 10,
                        right: 0,
                        transform: transform,
                        color: 'gray',
                    }}
                >
                    
                </label>
                <input
                    type={tipo}
                    onBlur={(e) => (ChangeInput(ValorInput), e.target.style.backgroundColor = 'white')}
                    onChange={(e) => (setValorInput(e.target.value))}
                    onClick={(e) => (setValorInput(e.target.value))}
                    // onEx
                    style={{
                        width: '100%',
                        border: '1px solid',
                        padding: '4px',
                        transition: 'border-color 0.10s',
                        borderRadius: '3px'

                    }}
                    onFocus={(e) => (e.target.style.backgroundColor = 'rgba(168, 0, 28,0.15)')}
                    // onBlur={(e) => (e.target.style.backgroundColor = 'white')}
                    value={ValorInput}
                />
            </div> */}
    </>
  );
}
