import { MobileOff, Sync } from "@mui/icons-material";
import { Typography, Button, Box, Container } from "@mui/material";
import { BuscaLocal } from "../../../controller/storage";

export function MobileBloqueado() {
  function Reload() {
    window.location = "/principal";
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box textAlign="center" sx={{ p: 4 }}>
        <Typography
          variant="h4"
          component="div"
          gutterBottom
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <MobileOff fontSize="large" />
          Celular Não Autorizado!
        </Typography>
        <Typography variant="h6" gutterBottom>
          Código de Autenticação
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <b>{BuscaLocal("user_agent")}</b>
        </Typography>
        <Button
          variant="contained"
          startIcon={<Sync />}
          onClick={Reload}
          sx={{ mt: 2 }}
        >
          Atualizar
        </Button>
      </Box>
    </Container>
  );
}
